import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsTabsComponent } from './notifications-tabs/notifications-tabs.component';
import { InvitationsComponent } from './invitations/invitations.component';
import { IonicModule } from '@ionic/angular';
import { MatTabsModule } from '@angular/material/tabs';
import { AppCommonsModule } from '../commons/app-commons.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotificationsComponent } from './notifications/notifications.component';
import { NotificationsTableComponent } from './notifications-table/notifications-table.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { RouterLinkWithHref } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import {App360SharedModule} from "@nutricontrol/app360-shared";

@NgModule({
  declarations: [
    NotificationsTabsComponent,
    InvitationsComponent,
    NotificationsComponent,
    NotificationsTableComponent,
  ],
    imports: [
        CommonModule,
        IonicModule,
        MatTabsModule,
        AppCommonsModule,
        ReactiveFormsModule,
        NgSelectModule,
        FormsModule,
        RouterLinkWithHref,
        MatTooltipModule,
        MatExpansionModule,
        App360SharedModule,
    ],
  exports: [NotificationsTableComponent],
})
export class NotificationsModule {}
