import { Component, Input, OnInit } from '@angular/core';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { UserIdentityDataModel } from '../../permission.model';
import { PermissionsTerminalsService } from '../../../services/libraries/permissions-terminals.service';

@Component({
  selector: 'app-permissions-table-injected-users',
  templateUrl: './permissions-table-injected-users.component.html',
  styleUrls: ['./permissions-table-injected-users.component.scss'],
})
export class PermissionsTableInjectedUsersComponent implements OnInit {
  @Input() regularUsers: UserIdentityDataModel[] = [];
  injectedRegularUsers: UserIdentityDataModel[] = [];
  constructor(
    public translationsLib: TranslationsLibService,
    public permissionsTerminal: PermissionsTerminalsService
  ) {}

  ngOnInit() {
    this.getInjectedUsers();
  }

  private getInjectedUsers() {
    this.regularUsers.forEach((regularUser) => {
      const injectionReason =
        regularUser.permission_regular_user.injection_reason || null;
      if (injectionReason !== null) {
        this.injectedRegularUsers.push(regularUser);
      }
    });
  }
}
