import { Component, Input, OnInit } from '@angular/core';
import { TerminalDataModel } from '../../../terminals/terminals.model';
import { TerminalsLibService } from '../../../services/libraries/terminals-lib.service';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { EquipmentGeneralTypes } from '../../../farming/farming.model';
import { DataloggerLibService } from '../../../farming/libraries/datalogger-lib.service';
import { ModuleDataModel } from '../../../modules/modules.model';
import { ModulesLibService } from '../../../services/libraries/modules-lib.service';
import { Et0LibService } from '../../../farming/libraries/et0-lib.service';
import { IrrigationEquipmentModel } from '../../../farming/irrigation/irrigation.model';
import { ClimateEquipmentModel } from '../../../farming/climate/climate.model';
import { ConfigurableEquipmentModel } from '../../../farming/configurable/configurable.model';
import { DataloggerEquipmentModel } from '../../../farming/datalogger/datalogger.model';

@Component({
  selector: 'app-main-dashboard-terminals-simple-meteo-pills',
  templateUrl: './main-dashboard-terminals-simple-meteo-pills.component.html',
  styleUrls: ['./main-dashboard-terminals-simple-meteo-pills.component.scss'],
})
export class MainDashboardTerminalsSimpleMeteoPillsComponent implements OnInit {
  @Input() irrigationConsultingModules: ModuleDataModel[] = [];
  @Input() terminals: TerminalDataModel[] = [];
  @Input() equipmentsData: (
    | IrrigationEquipmentModel
    | ClimateEquipmentModel
    | ConfigurableEquipmentModel
    | DataloggerEquipmentModel
  )[] = [];
  orderedByIndexEquipments: any[] = [];
  orderedByIndexEquipmentsPills: any[] = [];
  orderedByIndexEquipmentsLastMeasure: string[] = [];
  orderedByIndexIrrigationConsultingModule: any[] = [];
  orderedByIndexIrrigationConsultingBand: any[] = [];
  orderedByIndexIrrigationConsultingTensiometer: any[] = [];
  orderedByIndexET0: any[] = [];
  orderedByIndexStatus = [];
  pillsOrderDatalogger = [
    'SOIL_TENSION',
    'VWC',
    'SOIL_TEMP',
    'SOIL_CE',
    'TEMPERATURE',
    'HUMIDITY',
    'VPD',
    'ET0',
    'DWP',
    'WBT',
    'RADIATION',
    'WIND_VELOCITY',
    'PLUVIOMETER',
    'VOLTAGE',
    'SIGNAL',
  ];
  equipmentGeneralTypes;
  constructor(
    private et0Lib: Et0LibService,
    private modulesLib: ModulesLibService,
    public terminalsLib: TerminalsLibService,
    public translationsLib: TranslationsLibService,
    public dataloggerLib: DataloggerLibService
  ) {
    this.equipmentGeneralTypes = EquipmentGeneralTypes;
  }

  ngOnInit() {
    this.terminals.forEach((terminal, index) => {
      const equipmentsDataFound = this.equipmentsData.filter(
        (equipmentData) => {
          let serialNumber = 'error';
          if ('serialNumber' in equipmentData) {
            // Datalogger API return serialNumber as an integer
            serialNumber = String(equipmentData.serialNumber).toString();
          }
          return terminal.vid === serialNumber;
        }
      );
      if (equipmentsDataFound.length > 0) {
        this.orderedByIndexEquipments[index] = equipmentsDataFound[0];
        if (equipmentsDataFound[0].states.length > 0) {
          equipmentsDataFound[0].meteo.forEach((data) => {
            equipmentsDataFound[0].states.forEach((sensor) => {
              if (sensor.name === data.name && sensor.value === data.value) {
                if (sensor.model === 'WBT') {
                  data.key = 'WBT';
                }
                if (sensor.model === 'DWP') {
                  data.key = 'DWP';
                }
              }
            });
          });
        }
        let pills = equipmentsDataFound[0].meteo;
        this.orderedByIndexEquipmentsLastMeasure[index] =
          equipmentsDataFound[0].lastMeasure;
        this.et0Lib
          .getHistoricWeather(terminal)
          .then((historicWeatherResult) => {
            this.et0Lib
              .getET0(
                historicWeatherResult.calculateET0Request,
                equipmentsDataFound[0]
              )
              .then((et0Result) => {
                if (et0Result.yesterday_ET0 !== 'N/A') {
                  // Add first isolated of et0 webservice latency
                  this.orderedByIndexEquipmentsPills[index] = [
                    {
                      key: 'et0',
                      unit: 'mm',
                      value: et0Result.yesterday_ET0,
                      origin: 'VIRTUAL',
                    },
                  ].concat(this.orderedByIndexEquipmentsPills[index]);
                }
              });
          })
          .catch((error) => {
            console.log(error);
          });
        let text_key_prefix = '';
        if (
          this.terminalsLib.getType(terminal.type) ===
          EquipmentGeneralTypes.datalogger
        ) {
          pills = this.dataloggerLib.statesToPills(
            pills,
            equipmentsDataFound[0].states
          );
          pills = this.dataloggerLib.orderPills(
            pills,
            this.pillsOrderDatalogger
          );
          text_key_prefix = 'DL_PILLS_';
        }

        pills.forEach((pill, i) => {
          pills[i].temp_name = this.translationsLib.get(
            text_key_prefix + pill.key
          );
        });
        /*pills.sort((a, b) => a.temp_name.localeCompare(b.temp_name));*/

        this.orderedByIndexEquipmentsPills[index] = pills;

        const irrigationConsultingModuleFound =
          this.irrigationConsultingModules.filter(
            (irrigationConsultingModule) => {
              return terminal.vid === irrigationConsultingModule.terminal_vid;
            }
          );
        if (irrigationConsultingModuleFound.length > 0) {
          this.orderedByIndexStatus[index]= irrigationConsultingModuleFound[0].status;
          this.modulesLib
            .getModuleDataByModule(irrigationConsultingModuleFound[0])
            .subscribe((moduleData) => {
              this.orderedByIndexIrrigationConsultingBand[index] =
                this.modulesLib.getIrrigationBandFromModuleData(moduleData);
            });

          this.orderedByIndexIrrigationConsultingTensiometer[index] =
            this.dataloggerLib.getTensiometerForIrrigationConsulting(
              irrigationConsultingModuleFound[0],
              equipmentsDataFound[0].states
            );
        } else {
          this.orderedByIndexStatus[index] = null;
        }
      }
    });
  }

  protected getIrrigationEquipment(
    terminalVid: string
  ): IrrigationEquipmentModel {
    const irrigationEquipments = this.equipmentsData.filter(
      (equipment): equipment is IrrigationEquipmentModel => {
        return (
          (equipment as IrrigationEquipmentModel).serialNumber !== undefined
        );
      }
    );

    return irrigationEquipments.find(
      (equipment) => equipment.serialNumber === terminalVid
    );
  }
}
