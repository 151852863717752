import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PermissionsService } from '../permissions.service';
import { ActivatedRoute } from '@angular/router';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { UserIdentityDataModel } from '../permission.model';
@Component({
  selector: 'app-permissions-table',
  templateUrl: './permissions-table.component.html',
  styleUrls: ['./permissions-table.component.scss'],
})
export class PermissionsTableComponent implements OnInit {
  @Input() vid = '';
  @Input() canAdmin = false;
  @Input() owner: UserIdentityDataModel = null;
  @Input() installer: UserIdentityDataModel = null;
  @Input() regularUsers: UserIdentityDataModel[] = [];
  @Input() authedUserCanAdminInstaller = false;
  @Input() authedUserCanAdminOwner = false;
  @Input() contacts: UserIdentityDataModel[] = [];
  @Input() addUser = false;
  @Input() mode = '';
  @Output() getPermissionsList: EventEmitter<any> = new EventEmitter();
  constructor(
    private permissionService: PermissionsService,
    private route: ActivatedRoute,
    public translationsLib: TranslationsLibService
  ) {}

  ngOnInit(): void {
    if (this.vid === '') {
      this.vid = this.route.snapshot.paramMap.get('vid');
    }
    setTimeout(() => {
      //
    }, 100);
  }

  handlePermissionsReload() {
    this.getPermissionsList.emit();
  }
}
