import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PermissionsService } from '../permissions.service';
import { SessionLibService } from '@nutricontrol/app360-shared';
import { HelpersLibService } from '../../services/libraries/helpers-lib.service';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import {
  PermissionMappingIdentityDataModel,
  UserIdentityDataModel,
} from '../permission.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-terminal-permissions',
  templateUrl: './terminal-permissions.component.html',
  styleUrls: ['./terminal-permissions.component.scss'],
})
export class TerminalPermissionsComponent implements OnInit {
  @Input() vid = '';
  filteredOptions: Observable<string[]>;
  permissions: PermissionMappingIdentityDataModel;
  canAdmin = false;
  installer: UserIdentityDataModel = null;
  owner: UserIdentityDataModel = null;
  regularUsers: UserIdentityDataModel[] = [];
  contacts;
  ready = false;

  constructor(
    private permissionService: PermissionsService,
    private route: ActivatedRoute,
    private session: SessionLibService,
    private helpersLib: HelpersLibService,
    public translationsLib: TranslationsLibService
  ) {
    if (this.vid === '') {
      this.vid = this.route.snapshot.paramMap.get('vid');
    }
  }
  ngOnInit() {
    this.installer = this.installer || null;
    this.owner = this.owner || null;
    this.regularUsers = this.regularUsers || [];

    this.getPermissionsList();
  }

  getPermissionsList() {
    this.permissionService.getPermissionsList().subscribe((res) => {
      res.terminals_info.forEach((terminal) => {
        if (terminal.vid === this.vid) {
          this.permissions = terminal.permission_mapping_identity;
          this.canAdmin = terminal.authed_user_can_admin;
          this.helpersLib.setTitle(
            this.translationsLib.get('terminal_terminal'),
            terminal.name,
            this.translationsLib.get('permission_permissions')
          );
          this.deleteNulls();
          this.getContacts();
        }
      });
    });
  }

  deleteNulls() {
    if (this.permissions.installer !== null) {
      this.installer = this.permissions.installer;
    }
    if (this.permissions.owner !== null) {
      this.owner = this.permissions.owner;
    }
    if (this.permissions.regular_users !== null) {
      if ('regular_users' in this.permissions) {
        this.regularUsers = this.permissions.regular_users;
      }
      this.regularUsers = Object.values(this.regularUsers);
    }
  }

  getContacts() {
    this.permissionService
      .getUserContacts(this.session.getSessionVid())
      .subscribe((res) => {
        this.contacts = res;
        this.ready = true;
      });
  }
}
