import { Injectable } from '@angular/core';
import { ModuleDataModel } from '../../modules/modules.model';

@Injectable({
  providedIn: 'root',
})
export class DataloggerLibService {
  dataloggerFamily: { [key: string]: string[] } = {
    lite: ['LUXAR_LITE', 'LUXAR_LITE_V2'],
    V2: ['SYNION', 'LUXAR_V2'],
  };
  constructor() {}

  isFamily(familyKey: string, model): boolean {
    const family = this.dataloggerFamily[familyKey];
    return family ? family.includes(model) : false;
  }
  signalPercent(signal) {
    if (signal > -40) {
      return '100';
    }
    if (signal > -60 && signal < -40) {
      return '20';
    }
    if (signal > -70 && signal < -60) {
      return '15';
    }
    if (signal > -80 && signal < -70) {
      return '10';
    }
    if (signal < -80) {
      return '0';
    }
  }
  filterSensors(sensorsData, filterWord) {
    const data = [];
    sensorsData.forEach((sensor) => {
      if (sensor.type !== null && sensor.type.includes(filterWord)) {
        data.push(sensor);
      }
    });
    return data;
  }
  filterByNexpNent(sensorsData, sensorReal) {
    const data = [];
    if (sensorReal !== undefined) {
      sensorsData.forEach((sensor) => {
        if (
          sensor.nexp === sensorReal.nexp &&
          sensor.nent === sensorReal.nent &&
          sensor.physicalType === sensorReal.physicalType
        ) {
          data.push(sensor);
        }
      });
    }
    return data[0];
  }
  getArrayOfsensors(sensors) {
    const sensores = [];
    sensors.EA.forEach((sensor) => {
      if (sensor != null) {
        sensores.push(sensor);
      }
    });
    sensors.ED.forEach((sensor) => {
      if (sensor != null) {
        sensores.push(sensor);
      }
    });
    sensors.ED_BOARD.forEach((sensor) => {
      if (sensor != null) {
        sensores.push(sensor);
      }
    });
    sensors.SDI12 = Object.values(sensors.SDI12);
    sensors.SDI12.forEach((sensor) => {
      if (sensor != null) {
        if (sensor.length === undefined) {
          sensores.push(sensor);
        } else {
          sensor.forEach((sen) => {
            if (!sen.name) {
              sen.forEach((sensorInside) => {
                sensores.push(sensorInside);
              });
            } else {
              sensores.push(sen);
            }
          });
        }
      }
    });
    return sensores;
  }
  orderPills(pills, pillsOrder) {
    const pillsNewOrder = [];
    pillsOrder.forEach((magnitude) => {
      pills.forEach((pill) => {
        if (magnitude === pill.key) {
          if (magnitude === 'SIGNAL') {
            pill.value = this.signalPercent(pill.value);
            pill.unit = '%';
          }
          pillsNewOrder.push(pill);
        }
      });
    });
    return pillsNewOrder;
  }
  statesToPills(existingPills: any[], states: any[]): any[] {
    states.forEach((sonda: any) => {
      if (
        sonda.model !== 'ETc' &&
        sonda.model !== 'ET0' &&
        sonda.model !== 'SUPPLY_VOLTAGE'
      ) {
        if (!sonda.readingPoints) {
          if (existingPills.filter((s) => s.name === sonda.name).length === 0) {
            existingPills.push({
              name: sonda.name,
              key: sonda.magnitude,
              origin: 'SENSORCONNECTED',
              unit: sonda.unit,
              value: sonda.value,
              direction: sonda.sensorNumber,
            });
          }
        } else {
          sonda.readingPoints.forEach((point) => {
            if (
              existingPills.filter(
                (s) =>
                  (s.direction === undefined && s.name === point.name) ||
                  (s.name === point.name && s.direction === sonda.sensorNumber)
              ).length === 0
            ) {
              existingPills.push({
                name: point.name,
                direction: sonda.sensorNumber,
                key: point.magnitude,
                origin: 'SENSORCONNECTED',
                unit: point.unit,
                value: point.value,
              });
            }
          });
        }
      }
    });
    return existingPills;
  }

  getTensiometerForIrrigationConsulting(
    module: ModuleDataModel,
    states: any[]
  ): any {
    let tensiometro = null;
    states.forEach((probe) => {
      if (
        probe.model !== 'ETc' &&
        probe.model !== 'ET0' &&
        probe.model !== 'SIGNAL' &&
        probe.model !== 'SUPPLY_VOLTAGE' &&
        probe.model !== 'BATTERY_VOLTAGE'
      ) {
        if (!probe.readingPoints || probe.readingPoints.length <= 3) {
          if (!probe.readingPoints) {
            if (this.checkTensiometer(probe, module) !== undefined) {
              tensiometro = this.checkTensiometer(probe, module);
            }
          } else {
            probe.readingPoints.forEach((point) => {
              if (this.checkTensiometer(point, module) !== undefined) {
                tensiometro = this.checkTensiometer(point, module);
              }
            });
          }
        }
      }
    });
    return tensiometro;
  }
  checkTensiometer(probe, module) {
    let tensiometro;
    if (probe.magnitude === 'SOIL_TENSION' && module !== null) {
      const sens = module.irrigation_consulting_configuration;
      if (
        sens.sensor1.physicalType === 0 ||
        sens.sensor1.physicalType === 15
      ) {
        if (
          sens.sensor1.nexp === probe.nexp &&
          (sens.sensor1.nent === probe.sensorNumber ||
            sens.sensor1.nent === probe.sensorNumber * 2 + probe.readingPoint)
        ) {
          tensiometro = probe;
        }
      } else {
        if (
          sens.sensor2.nexp === probe.nexp &&
          (sens.sensor2.nent === probe.sensorNumber ||
            sens.sensor2.nent === probe.sensorNumber * 2 + probe.readingPoint)
        ) {
          tensiometro = probe;
        }
      }
    }
    return tensiometro;
  }
}
