<div class="reports-alarms-table-container pt-3 pb-3" *ngIf="ready === true">
  <ng-container *ngIf="dataSource">
  <form [formGroup]="filterForm">
    <div mat-dialog-content class="filter__form">
      <div class="row">
        <div class="col-12">
          <h4 class="mb-0">{{ translationsLib.get('reports_filter') }}</h4>
        </div>

        <div class="col-6 col-lg-2 form-group mb-0">
          <mat-form-field>
            <input matInput formControlName="typeStr" placeholder="{{ translationsLib.get('reports_typeStr') }}">
          </mat-form-field>
        </div>
        <div class="col-6 col-lg-2 form-group mb-0">
          <mat-form-field>
            <input matInput formControlName="active" placeholder="{{ translationsLib.get('reports_active') }}">
          </mat-form-field>
        </div>
        <div class="col-6 col-lg-2 form-group mb-0">
          <mat-form-field>
            <input matInput formControlName="whoActivatesStr" placeholder="{{ translationsLib.get('reports_whoActivatesStr') }}">
          </mat-form-field>
        </div>
        <div class="col-6 col-lg-2 form-group mb-0">
          <mat-form-field>
            <input matInput formControlName="begin" placeholder="{{ translationsLib.get('reports_begin') }}">
          </mat-form-field>
        </div>
        <div class="col-6 col-lg-2 form-group mb-0">
          <mat-form-field>
            <input matInput formControlName="end" placeholder="{{ translationsLib.get('reports_end') }}">
          </mat-form-field>
        </div>

        <div class="col-6 col-lg-2 form-group mb-0 text-end pt-3">
          <a [matMenuTriggerFor]="menu">
            <span>{{ translationsLib.get('reports_data_tools') }}</span>
            <button mat-icon-button>
              <ion-icon name="ellipsis-vertical-outline"></ion-icon>
            </button>
          </a>
          <mat-menu #menu="matMenu">
            <button mat-menu-item>
              <ion-icon name="reader-outline"></ion-icon>
              <button mat-button (click)="exporter.exportTable('xls',{fileName: reportName})">
                {{ translationsLib.get('highcharts_download_xls') }}
              </button>
            </button>
            <button mat-menu-item>
              <ion-icon name="document-outline"></ion-icon>
              <button mat-button (click)="exportToPDF()">
                {{ translationsLib.get('highcharts_download_pdf') }}
              </button>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </form>
    <table mat-table
           [dataSource]="dataSource" multiTemplateDataRows matSort
           class="mat-elevation-z8 alarms-table"
           matTableExporter #exporter="matTableExporter"
    >
      <ng-container matColumnDef="{{ column }}" *ngFor="let column of alarmColumnsToDisplay">
        <th class="px-1" mat-header-cell *matHeaderCellDef mat-sort-header> {{ translationsLib.get('reports_' + column) }}</th>
        <td class="px-1" mat-cell *matCellDef="let element" [ngClass]="{'warning' : element.incidence === true}">
            {{ element[column] }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="alarmColumnsToDisplay"></tr>
      <tr mat-row *matRowDef="let element; columns: alarmColumnsToDisplay;"
          class="table-row"
          [class.table-expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element">
      </tr>
    </table>
    <mat-paginator
      [length]="count"
      [pageSize]="this.tablePageItemNumber"
      showFirstLastButtons
    >
    </mat-paginator>
  </ng-container>
</div>
<app-reports-download-post-open-window [requestData]="this.requestData" *ngIf="this.downloadPDF === true"></app-reports-download-post-open-window>
