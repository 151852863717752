<aside class="loader-container" *ngIf="reportsData.length < createdReport.terminals.length">
  <app-loader></app-loader>
</aside>

<section class="reports-main-subcomponent-container" *ngFor="let reportData of reportsData;">
  <div class="card pb-3" *ngIf="reportData.ready === true">
    <div class="card-header">
      <!-- Title -->
      <h2 class="card-header-title mb-3">
        {{ reportData.terminal_name_vid }}
      </h2>
      <h2 class="card-header-subtitle">
        {{ reportData.terminal_field_name }}
      </h2>
    </div>
    <div class="row mx-2 mt-3 print-no-padding">
      <div class="col-12 p-0 p-lg-2 irrigation-container print-no-padding">

        <div class="alert alert-info mb-2" role="alert" *ngIf="reportData.items.length === 0">
          {{ translationsLib.get('reports_no_data') }}
        </div>

        <article *ngIf="reportData.items.length > 0 && ready===true">
          <div class="row" >
            <div class="col-12" *ngIf="!hasRealWaterConsumption">
              <h4 class="my-0" style="font-style: italic; font-weight: normal">{{translationsLib.get('reports_period_water_consumption_0')}}</h4>
            </div>
            <picture class="col-12" *ngIf="hasRealWaterConsumption">
              <highcharts-chart
                [Highcharts]="Highcharts"
                [constructorType]="chartConstructor"
                [options]="reportData.chart"
                [(update)]="updateFlag"
                [callbackFunction]="chartCallback"
                [oneToOne]="true"
                style="width: 100%; min-height: 400px; display: block;"
              >
              </highcharts-chart>
            </picture>
          </div>
          <!-- MODERN DESKTOP -->
          <app-reports-irrigations-table
            *ngIf="createdReport.simpleTable === false"
            [reportName]="reportName"
            [irrigationColumnsToDisplay]="irrigationColumnsToDisplay"
            [fertilizerColumnsToDisplay]="fertilizerColumnsToDisplay"
            [data]="reportData.items"
            [count]="reportData.items.length"
            [filterProgramList]="filterProgramList"
            [tablePageItemNumber]="createdReport.tablePageItemNumber"
            [requestData]="{terminals_vid: [reportData.terminal_vid], end: this.requestData.end, begin: this.requestData.begin, type: 'irrigations'}"
          ></app-reports-irrigations-table>
        </article>

      </div>
    </div>
  </div>
</section>
