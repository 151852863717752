<div class="container-fluid px-0 p-lg-2">
  <div class="row m-0 m-lg-2">
    <div class="col-12 my-2" *ngFor="let terminal of terminals; let i = index; let first = first ;let last = last;">
      <div class="row">
        <div class="col-12 col-md-3 px-1">
          <app-device-header [equipment]="terminal" [showButton]="false"></app-device-header>
        </div>
        <div class="col-12 col-md-9 meteo-pills-container">
            <ng-container *ngIf="this.orderedByIndexEquipmentsPills[i] && this.orderedByIndexEquipmentsPills[i].length > 0">

              <ng-container *ngIf="this.terminalsLib.getType(terminal.type) === this.equipmentGeneralTypes.irrigation">
                <div class="row">
                  <div class="col-12 p-1 meteo-pills">
                    <app-water-systems
                      [equipment]="getIrrigationEquipment(terminal.vid)"
                      [showLastConnection]="false"
                    ></app-water-systems>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="this.terminalsLib.getType(terminal.type) === 'UNKNOWN'">
                <div class="row">
                  <div class="col-12 my-2">
                    <i>{{ translationsLib.get('no_results') }}</i>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="this.terminalsLib.getType(terminal.type) !== 'UNKNOWN'">
                <a class="row meteo-pills" [href]="this.terminalsLib.generateRoute(terminal.vid, terminal.type)" (click)="this.terminalsLib.goToRoute(terminal)">
                  <!-- IRRIGATION CONSULTING PILLS -->
                  <div class="col-12 col-lg-4 mb-2 px-1"
                       *ngIf="(orderedByIndexIrrigationConsultingBand[i] && orderedByIndexIrrigationConsultingTensiometer[i]) || orderedByIndexStatus[i] ===2 "
                  >
                    <app-terminal-irrigation-consulting-pill
                      [class]="'mini'"
                      [irrigationBand]="orderedByIndexIrrigationConsultingBand[i]"
                      [tensiometer]="orderedByIndexIrrigationConsultingTensiometer[i]"
                      [status]="orderedByIndexStatus[i]"
                    ></app-terminal-irrigation-consulting-pill>
                  </div>
                  <!-- REGULAR PILLS -->
                  <div class="col-auto mb-2 px-1" *ngFor="let pill of this.orderedByIndexEquipmentsPills[i]">

                    <div class="card p-2" [ngClass]="this.terminalsLib.getType(terminal.type)">
                      <div class="row align-items-center">
                        <div class="col text-uppercase data-header" *ngIf="pill.origin !== 'SENSORCONNECTED'">
                          <ng-container [ngSwitch]="this.terminalsLib.getType(terminal.type)">
                            <!--
                            <ng-container *ngSwitchCase="'CLIMATE'">
                              {{translationsLib.get('climate_'+ meteo.key)}}
                            </ng-container>
                            -->
                            <ng-container *ngSwitchCase="'DATALOGGER'">
                              {{translationsLib.get('DL_PILLS_'+ pill.key)}}
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                              {{translationsLib.get(pill.key)}}
                            </ng-container>
                          </ng-container>
                        </div>
                        <div class="col text-uppercase data-header" *ngIf="pill.origin === 'SENSORCONNECTED'">
                          {{pill.name}}
                        </div>
                      </div>
                      <div class="row align-items-center">
                        <div class="col-auto data-value">
                          <b class="h2 mb-0"> {{pill.value || '-'}} {{pill.unit}}
                            <!-- <span *ngIf="meteo.origin !== 'SENSOR' && meteo.origin !== 'SENSORCONNECTED'"  style="color: #ee7869; font-size: 10px; font-weight: bold"> (V)</span> -->
                          </b>
                        </div>
                        <div class="d-none d-lg-block text-end col" [innerHTML]="pill.key | sensorIcon: 'color: #ee7869; min-height:22px; font-weight: bold;'"></div>
                      </div>
                    </div>
                  </div>
                  <!-- LAST MEASURE -->
                  <div class="col-12 px-2 last-measure text-muted">
                      <b>
                        {{ translationsLib.get('terminal_last_connection') }}
                      </b> {{ this.orderedByIndexEquipmentsLastMeasure[i] | transformDateTime }}
                  </div>
                </a>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!this.orderedByIndexEquipmentsPills[i] || this.orderedByIndexEquipmentsPills[i].length === 0">
              <div class="row">
                <div class="col-12 my-2 px-1">
                  <i>{{ translationsLib.get('no_results') }}</i>
                </div>
              </div>
            </ng-container>
        </div>
      </div>
      <div class="row" *ngIf="!last">
        <div class="col-12 mb-2"></div>
      </div>
    </div>
  </div>
</div>

