import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestApiService } from '../../services/api/rest-api.service';
import {
  AgitatorsIrrigationInstallerModel,
  DemandsDataModel,
  DemandsIrrigationInstallerModel,
  DrainageModel,
  DrainageTrayModel,
  DrainageTraysIrrigationInstallerModel,
  DrainageV3Model,
  DrainageV4Model,
  FertilizerFormulasModel,
  FiltersIrrigationInstallerModel,
  FertilizersIrrigationInstallerModel,
  FertilizersNuveIrrigationModel,
  GroupsDataModel,
  InputOutputResponseModel,
  IOReportIrrigationInstallerModel,
  MeteoAlarmsModel,
  MiscellaneousIrrigationInstallerModel,
  MixtureFormulasModel,
  MixtureValvesIrrigationInstallerModel,
  PhCeRearmAlarmsIrrigationInstallerModel,
  ProgramModel,
  PumpsDataModel,
  PumpsIrrigationInstallerModel,
  SensorDataModel,
  TerminalLayoutModelIrrigation,
  ValvesDataModel,
  ValvesIrrigationInstallerModel,
  WaterSystemIrrigationInstallerModel,
  IrrigationEquipmentModel,
  TerminalLayoutModelIrrigationSubmit,
  EquipmentSubmitModel,
} from './irrigation.model';
import { TerminalDataModel } from '../../terminals/terminals.model';
import { WeatherCalculateET0Request } from '../../weather/weather.model';
import { CancelAlarmsModel } from '../climate/climate.model';

/*************************
 ******* IRRIGATION ******
 ************************/

@Injectable({
  providedIn: 'root',
})
export class IrrigationService {
  private restApiService: RestApiService;

  constructor(restApiService: RestApiService) {
    this.restApiService = restApiService;
  }

  /******** FERTILIZER FORMULAS *******/

  public getFertilizerFormulas(
    terminalVid: string
  ): Observable<FertilizerFormulasModel[]> {
    return new Observable((observer) => {
      this.restApiService.getFertilizerFormulas(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public getFertilizerFormula(
    terminalVid: string,
    id: number
  ): Observable<FertilizerFormulasModel[]> {
    return new Observable((observer) => {
      this.restApiService.getFertilizerFormula(terminalVid, id).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postFertilizerFormulas(
    terminalVid: string,
    fertilizerFormulas: FertilizerFormulasModel[]
  ): Observable<FertilizerFormulasModel> {
    return new Observable((observer) => {
      this.restApiService
        .postFertilizerFormulas(terminalVid, fertilizerFormulas)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  /******** GROUPS *******/

  public getIrrigationGroups(
    terminalVid: string
  ): Observable<GroupsDataModel[]> {
    return new Observable((observer) => {
      this.restApiService.getIrrigationGroups(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public getIrrigationGroup(
    terminalVid: string,
    groupId: number
  ): Observable<GroupsDataModel[]> {
    return new Observable((observer) => {
      this.restApiService.getIrrigationGroup(terminalVid, groupId).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postIrrigationGroups(
    terminalVid: string,
    groups: GroupsDataModel[]
  ): Observable<GroupsDataModel> {
    return new Observable((observer) => {
      this.restApiService.postIrrigationGroups(terminalVid, groups).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  /******** PROGRAMS *******/

  public getIrrigationPrograms(
    terminalVid: string
  ): Observable<ProgramModel[]> {
    return new Observable((observer) => {
      this.restApiService.getIrrigationPrograms(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public getIrrigationProgram(
    terminalVid: string,
    programId: number
  ): Observable<ProgramModel[]> {
    return new Observable((observer) => {
      this.restApiService
        .getIrrigationProgram(terminalVid, programId)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  public postIrrigationPrograms(
    terminalVid: string,
    programs: ProgramModel[]
  ): Observable<ProgramModel> {
    return new Observable((observer) => {
      this.restApiService
        .postIrrigationPrograms(terminalVid, programs)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  public postManualActionsIrrigationProgram(
    terminalVid: string,
    payload: object
  ): Observable<ProgramModel> {
    return new Observable((observer) => {
      this.restApiService
        .postManualActionsIrrigationProgram(terminalVid, payload)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  /******** VALVES *******/

  public getValves(terminalVid: string): Observable<ValvesDataModel[]> {
    return new Observable((observer) => {
      this.restApiService.getValves(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  /******** SENSORS *******/

  public getSensors(terminalVid: string): Observable<SensorDataModel[]> {
    return new Observable((observer) => {
      this.restApiService.getSensors(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public putSensor(
    terminalVid: string,
    sensor: SensorDataModel
  ): Observable<SensorDataModel> {
    return new Observable((observer) => {
      this.restApiService.putSensor(terminalVid, sensor).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
  public getSensorsSubtypes(
    terminalVid: string
  ): Observable<SensorDataModel[]> {
    return new Observable((observer) => {
      this.restApiService.getSensorsSubtypes(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  /******** PUMPS *******/

  public getPumps(terminalVid: string): Observable<PumpsDataModel[]> {
    return new Observable((observer) => {
      this.restApiService.getPumps(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  /******** DEMANDS *******/

  public getDemands(terminalVid: string): Observable<DemandsDataModel[]> {
    return new Observable((observer) => {
      this.restApiService.getDemands(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  /******** PROGRAMS *******/

  public getPrograms(terminalVid: string): Observable<ProgramModel[]> {
    return new Observable((observer) => {
      this.restApiService.getPrograms(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postPrograms(
    terminalVid: string,
    programs: ProgramModel[]
  ): Observable<ProgramModel> {
    return new Observable((observer) => {
      this.restApiService.postPrograms(terminalVid, programs).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  /******** EQUIPMENT *******/

  public getIrrigationTerminal(
    terminalVid: string
  ): Observable<IrrigationEquipmentModel> {
    return new Observable((observer) => {
      this.restApiService.getIrrigationEquipment(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public getIrrigationEquipmentWithWaterSystems(
    terminalVid: string
  ): Observable<IrrigationEquipmentModel> {
    return new Observable((observer) => {
      this.restApiService
        .getIrrigationEquipmentWithWaterSystems(terminalVid)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  public postIrrigationTerminal(
    terminalVid: string,
    info: EquipmentSubmitModel
  ): Observable<WeatherCalculateET0Request> {
    return new Observable((observer) => {
      this.restApiService
        .postIrrigatrionClimateEquipment(terminalVid, info)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  /******** DRAINAGES *******/

  public getDrainageTrays(
    terminalVid: string
  ): Observable<DrainageTrayModel[]> {
    return new Observable((observer) => {
      this.restApiService.getDrainageTrays(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public getDrainages(terminalVid: string): Observable<DrainageModel> {
    return new Observable((observer) => {
      this.restApiService.getDrainages(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public getDrainage(
    terminalVid: string,
    drainageId: number
  ): Observable<DrainageModel> {
    return new Observable((observer) => {
      this.restApiService.getDrainage(terminalVid, drainageId).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public getDrainagesV4(terminalVid: string): Observable<DrainageV4Model[]> {
    return new Observable((observer) => {
      this.restApiService.getDrainagesV4(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postDrainagesV4(
    terminalVid: string,
    drainages: DrainageV4Model[]
  ): Observable<DrainageV4Model> {
    return new Observable((observer) => {
      this.restApiService.postDrainagesV4(terminalVid, drainages).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public getDrainagesV3(terminalVid: string): Observable<DrainageV3Model[]> {
    return new Observable((observer) => {
      this.restApiService.getDrainagesV3(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postDrainagesV3(
    terminalVid: string,
    drainages: DrainageV3Model[]
  ): Observable<DrainageV3Model> {
    return new Observable((observer) => {
      this.restApiService.postDrainagesV3(terminalVid, drainages).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  /******** MIXTURE FORMULAS *******/

  public getMixtureFormulas(
    terminalVid: string
  ): Observable<MixtureFormulasModel[]> {
    return new Observable((observer) => {
      this.restApiService.getMixtureFormulas(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public getMixtureFormula(
    terminalVid: string,
    mixtureId: number
  ): Observable<MixtureFormulasModel[]> {
    return new Observable((observer) => {
      this.restApiService.getMixtureFormula(terminalVid, mixtureId).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postMixtureFormulas(
    terminalVid: string,
    formulas: MixtureFormulasModel[]
  ): Observable<MixtureFormulasModel> {
    return new Observable((observer) => {
      this.restApiService.postMixtureFormulas(terminalVid, formulas).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  /******** INPUT OUTPUT *******/

  getInputOutputs(
    terminalVid: string,
    mode: string,
    type: number
  ): Observable<InputOutputResponseModel> {
    return new Observable((observer) => {
      this.restApiService.getInputOutputs(terminalVid, mode, type).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  /******** METEO ALARMS *******/

  public getMeteoAlarms(terminalVid: string): Observable<MeteoAlarmsModel[]> {
    return new Observable((observer) => {
      this.restApiService.getMeteoAlarms(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postMeteoAlarms(
    terminalVid: string,
    meteoAlarms: MeteoAlarmsModel[]
  ): Observable<MeteoAlarmsModel> {
    return new Observable((observer) => {
      this.restApiService.postMeteoAlarms(terminalVid, meteoAlarms).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  /******** TERMINAL LAYOUT *******/

  public getTerminalLayoutConfiguration(
    terminalVid: string
  ): Observable<TerminalLayoutModelIrrigation> {
    return new Observable((observer) => {
      this.restApiService.getTerminalLayoutConfiguration(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public putTerminalLayoutConfiguration(
    terminalVid: string,
    config: TerminalLayoutModelIrrigationSubmit
  ): Observable<TerminalLayoutModelIrrigationSubmit> {
    return new Observable((observer) => {
      this.restApiService
        .putTerminalLayoutConfiguration(terminalVid, config)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  /******** TERMINAL *******/

  public getTerminal(terminalVid: string): Observable<TerminalDataModel> {
    return new Observable((observer) => {
      this.restApiService.getTerminalIrrigation(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  /***********************************
   ******* IRRIGATION INSTALLER ******
   **********************************/

  /******** VALVES *******/

  public getIrrigationInstallerValves(
    terminalVid: string
  ): Observable<ValvesIrrigationInstallerModel[]> {
    return new Observable((observer) => {
      this.restApiService.getIrrigationInstallerValves(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public getIrrigationInstallerValve(
    terminalVid: string,
    valveId: number
  ): Observable<ValvesIrrigationInstallerModel[]> {
    return new Observable((observer) => {
      this.restApiService
        .getIrrigationInstallerValve(terminalVid, valveId)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  public postIrrigationInstallerValves(
    terminalVid: string,
    valves: ValvesIrrigationInstallerModel[]
  ): Observable<ValvesIrrigationInstallerModel> {
    return new Observable((observer) => {
      this.restApiService
        .postIrrigationInstallerValves(terminalVid, valves)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  /******** DRAINAGE TRAYS *******/

  public getIrrigationInstallerDrainageTrays(
    terminalVid: string
  ): Observable<DrainageTraysIrrigationInstallerModel[]> {
    return new Observable((observer) => {
      this.restApiService
        .getIrrigationInstallerDrainageTrays(terminalVid)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  public getIrrigationInstallerDrainageTray(
    terminalVid: string,
    trayId: number
  ): Observable<DrainageTraysIrrigationInstallerModel[]> {
    return new Observable((observer) => {
      this.restApiService
        .getIrrigationInstallerDrainageTray(terminalVid, trayId)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  public postIrrigationInstallerDrainageTrays(
    terminalVid: string,
    drainage_trays: DrainageTraysIrrigationInstallerModel[]
  ): Observable<DrainageTraysIrrigationInstallerModel> {
    return new Observable((observer) => {
      this.restApiService
        .postIrrigationInstallerDrainageTrays(terminalVid, drainage_trays)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  /******** DEMANDS *******/

  public getIrrigationInstallerDemands(
    terminalVid: string
  ): Observable<DemandsIrrigationInstallerModel[]> {
    return new Observable((observer) => {
      this.restApiService.getIrrigationInstallerDemands(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public getIrrigationInstallerDemand(
    terminalVid: string,
    demandId: number
  ): Observable<DemandsIrrigationInstallerModel[]> {
    return new Observable((observer) => {
      this.restApiService
        .getIrrigationInstallerDemand(terminalVid, demandId)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  public postIrrigationInstallerDemands(
    terminalVid: string,
    demands: DemandsIrrigationInstallerModel[]
  ): Observable<DemandsIrrigationInstallerModel> {
    return new Observable((observer) => {
      this.restApiService
        .postIrrigationInstallerDemands(terminalVid, demands)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  /******** PH CE REARM ALARMS *******/

  public getIrrigationInstallerPHCERearmAlarms(
    terminalVid: string
  ): Observable<PhCeRearmAlarmsIrrigationInstallerModel> {
    return new Observable((observer) => {
      this.restApiService
        .getIrrigationInstallerPHCERearmAlarms(terminalVid)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  public postIrrigationInstallerPHCERearmAlarms(
    terminalVid: string,
    ph_ce_rearm_alarms: PhCeRearmAlarmsIrrigationInstallerModel
  ): Observable<PhCeRearmAlarmsIrrigationInstallerModel> {
    return new Observable((observer) => {
      this.restApiService
        .postIrrigationInstallerPHCERearmAlarms(terminalVid, ph_ce_rearm_alarms)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  /******** PUMPS *******/

  public getIrrigationInstallerPump(
    terminalVid: string,
    pumpId: number
  ): Observable<PumpsIrrigationInstallerModel[]> {
    return new Observable((observer) => {
      this.restApiService
        .getIrrigationInstallerPump(terminalVid, pumpId)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }
  public getIrrigationInstallerPumps(
    terminalVid: string
  ): Observable<PumpsIrrigationInstallerModel[]> {
    return new Observable((observer) => {
      this.restApiService.getIrrigationInstallerPumps(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public postIrrigationInstallerPumps(
    terminalVid: string,
    pumps: PumpsIrrigationInstallerModel[]
  ): Observable<PumpsIrrigationInstallerModel> {
    return new Observable((observer) => {
      this.restApiService
        .postIrrigationInstallerPumps(terminalVid, pumps)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  /******** MIXTURE VALVES *******/

  public getIrrigationInstallerMixtureValves(
    terminalVid: string
  ): Observable<MixtureValvesIrrigationInstallerModel[]> {
    return new Observable((observer) => {
      this.restApiService
        .getIrrigationInstallerMixtureValves(terminalVid)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  public getIrrigationInstallerMixtureValve(
    terminalVid: string,
    mixtureValveId
  ): Observable<MixtureValvesIrrigationInstallerModel[]> {
    return new Observable((observer) => {
      this.restApiService
        .getIrrigationInstallerMixtureValve(terminalVid, mixtureValveId)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  public postIrrigationInstallerMixtureValves(
    terminalVid: string,
    mixture_valves: MixtureValvesIrrigationInstallerModel[]
  ): Observable<MixtureValvesIrrigationInstallerModel> {
    return new Observable((observer) => {
      this.restApiService
        .postIrrigationInstallerMixtureValves(terminalVid, mixture_valves)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  /******** WATER SYSTEMS *******/

  public getIrrigationInstallerWaterSystems(
    terminalVid: string
  ): Observable<WaterSystemIrrigationInstallerModel[]> {
    return new Observable((observer) => {
      this.restApiService
        .getIrrigationInstallerWaterSystems(terminalVid)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  public postIrrigationInstallerWaterSystems(
    terminalVid: string,
    waterSystem: WaterSystemIrrigationInstallerModel[]
  ): Observable<WaterSystemIrrigationInstallerModel> {
    return new Observable((observer) => {
      this.restApiService
        .postIrrigationInstallerWaterSystems(terminalVid, waterSystem)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  /******** FILTERS *******/

  public getIrrigationInstallerFilters(
    terminalVid: string
  ): Observable<FiltersIrrigationInstallerModel[]> {
    return new Observable((observer) => {
      this.restApiService.getIrrigationInstallerFilters(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public getIrrigationInstallerFilter(
    terminalVid: string,
    filterId: number
  ): Observable<FiltersIrrigationInstallerModel[]> {
    return new Observable((observer) => {
      this.restApiService
        .getIrrigationInstallerFilter(terminalVid, filterId)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  public postIrrigationInstallerFilters(
    terminalVid: string,
    filters: FiltersIrrigationInstallerModel[]
  ): Observable<FiltersIrrigationInstallerModel> {
    return new Observable((observer) => {
      this.restApiService
        .postIrrigationInstallerFilters(terminalVid, filters)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  /******** MISCELLANEOUS *******/

  public getIrrigationInstallerMiscellaneous(
    terminalVid: string
  ): Observable<MiscellaneousIrrigationInstallerModel> {
    return new Observable((observer) => {
      this.restApiService
        .getIrrigationInstallerMiscellaneous(terminalVid)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  public postIrrigationInstallerMiscellaneous(
    terminalVid: string,
    miscellaneous: MiscellaneousIrrigationInstallerModel
  ): Observable<MiscellaneousIrrigationInstallerModel> {
    return new Observable((observer) => {
      this.restApiService
        .postIrrigationInstallerMiscellaneous(terminalVid, miscellaneous)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  /******** FERTILIZERS *******/

  public getIrrigationInstallerFertilizers(
    terminalVid: string
  ): Observable<FertilizersIrrigationInstallerModel[]> {
    return new Observable((observer) => {
      this.restApiService
        .getIrrigationInstallerFertilizers(terminalVid)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  public postIrrigationInstallerFertilizers(
    terminalVid: string,
    fertilizers: FertilizersIrrigationInstallerModel[]
  ): Observable<FertilizersIrrigationInstallerModel> {
    return new Observable((observer) => {
      this.restApiService
        .postIrrigationInstallerFertilizers(terminalVid, fertilizers)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  /******** AGITATORS *******/

  public getIrrigationInstallerAgitators(
    terminalVid: string
  ): Observable<AgitatorsIrrigationInstallerModel[]> {
    return new Observable((observer) => {
      this.restApiService
        .getIrrigationInstallerAgitators(terminalVid)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  public postIrrigationInstallerAgitators(
    terminalVid: string,
    agitators: AgitatorsIrrigationInstallerModel[]
  ): Observable<AgitatorsIrrigationInstallerModel> {
    return new Observable((observer) => {
      this.restApiService
        .postIrrigationInstallerAgitators(terminalVid, agitators)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  /******** FERTILIZERS UCROP *******/

  public getIrrigationInstallerFertilizersNuve(
    terminalVid: string
  ): Observable<FertilizersNuveIrrigationModel> {
    return new Observable((observer) => {
      this.restApiService
        .getIrrigationInstallerFertilizersNuve(terminalVid)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  public postIrrigationInstallerFertilizersNuve(
    terminalVid: string,
    payload: FertilizersNuveIrrigationModel
  ): Observable<FertilizersNuveIrrigationModel> {
    return new Observable((observer) => {
      this.restApiService
        .postIrrigationInstallerFertilizersNuve(terminalVid, payload)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  /******** IO REPORT *******/

  public getIrrigationInstallerIOReport(
    terminalVid: string
  ): Observable<IOReportIrrigationInstallerModel> {
    return new Observable((observer) => {
      this.restApiService.getIrrigationInstallerIOReport(terminalVid).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  /******** CANCEL ALARMS *******/
  public postCancelAlarmsIrrigation(
    terminalVid: string,
    data: CancelAlarmsModel
  ): Observable<CancelAlarmsModel> {
    return new Observable((observer) => {
      this.restApiService
        .postCancelAlarmsIrrigation(terminalVid, data)
        .subscribe(
          (response) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }
}
