import * as moment from 'moment';
import { FormArray, UntypedFormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import {
  IrrigationEquipmentAlarm,
  MeteoIrrigationNew,
} from '../../farming/irrigation/irrigation.model';
import {
  ClimateEquipmentAlarm,
  MeteoClimateNew,
} from '../../farming/climate/climate.model';
import { environment } from '../../../environments/environment';

export const secondsInputToDate = (seconds: number) => {
  return moment(
    seconds * 1000 +
      new Date(seconds).getTimezoneOffset() * environment.intervalDefaultTimeout
  ).toDate();
};

export const convertFormattedTimeToSeconds = (hms) => {
  const a = hms.split(':');
  return +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
};

export const convertMMSSFormattedTimeToSeconds = (mmss: string) => {
  const a = mmss.split(':');
  return +a[0] * 60 + +a[1];
};

export const getZoneNameByZone = (zone: number, arr: any): string | null => {
  const currentZone = arr.find((zoneObject) => zoneObject.zone === zone);

  return currentZone ? currentZone.name : null;
};

export const DateToSecondsInput = (date: Date) => {
  const time = date.toTimeString();
  // @ts-ignore
  const seconds = time.substring(6, 8) * 1;
  // @ts-ignore
  const minutes = time.substring(3, 5) * 60;
  // @ts-ignore
  const hours = time.substring(0, 2) * 3600;
  return hours + minutes + seconds;
};

export const removeDirtyInputs = (myForm: UntypedFormGroup) => {
  myForm.markAsUntouched();
  myForm.markAsPristine();

  const dirtyInputs = document.querySelectorAll('input');
  const dirtySelects = document.querySelectorAll('select');
  const forms = document.querySelectorAll('form');

  dirtyInputs.forEach((elem) => {
    if (elem.classList.contains('ng-dirty')) {
      elem.classList.remove('ng-dirty');
    }

    if (elem.classList.contains('ng-touched')) {
      elem.classList.remove('ng-touched');
    }
  });

  dirtySelects.forEach((elem) => {
    if (elem.classList.contains('ng-dirty')) {
      elem.classList.remove('ng-dirty');
    }

    if (elem.classList.contains('ng-touched')) {
      elem.classList.remove('ng-touched');
    }
  });

  forms.forEach((elem) => {
    if (elem.classList.contains('ng-dirty')) {
      elem.classList.remove('ng-dirty');
    }

    if (elem.classList.contains('ng-touched')) {
      elem.classList.remove('ng-touched');
    }
  });
};

// This is a function to test forms. Don't delete it!
export const checkInvalidFormInput = (form: UntypedFormGroup) => {
  for (const controlName in form.controls) {
    if (form.controls.hasOwnProperty(controlName)) {
      const control = form.controls[controlName];
      if (control.invalid) {
        console.log(`El campo ${controlName} es inválido`);
      }
    }
  }
};

export const stepDispatchInputEvent = (
  input: HTMLInputElement,
  type: string
) => {
  if (type === 'up') {
    input.stepUp();
    input.dispatchEvent(new Event('input'));
  }

  if (type === 'down') {
    input.stepDown();
    input.dispatchEvent(new Event('input'));
  }
};

export const saveSubmitWithTerminalStatus = (
  res,
  showModal: boolean,
  connected: boolean,
  errorText: string,
  successText: string,
  confirmButtonText: string,
  disconnectedText: string
) => {
  if (res.error) {
    if (showModal) {
      Swal.fire({
        text: errorText,
        showConfirmButton: true,
        confirmButtonText,
        icon: 'error',
      });
    }
  } else {
    if (showModal) {
      if (!connected) {
        Swal.fire({
          text: disconnectedText,
          showConfirmButton: true,
          confirmButtonText,
          icon: 'error',
        });
      } else {
        Swal.fire({
          text: successText,
          showConfirmButton: true,
          confirmButtonText,
          icon: 'success',
        });
      }
    }
  }
};

export const saveDataAndShowModal = (
  res,
  showModal: boolean,
  errorText: string,
  successText: string,
  confirmButtonText: string,
  getDataAfterPost?: () => void
) => {
  if (res.error) {
    if (showModal) {
      Swal.fire({
        text: errorText,
        showConfirmButton: true,
        confirmButtonText,
        icon: 'error',
      });
    }
  } else {
    if (showModal) {
      Swal.fire({
        text: successText,
        showConfirmButton: true,
        confirmButtonText,
        icon: 'success',
      }).then(getDataAfterPost);
    }
  }
};

export const generateMeteoGrid = (
  meteo: (MeteoClimateNew | MeteoIrrigationNew)[]
) => {
  return meteo;
};

export const generateAlarmGrid = (
  alarms: IrrigationEquipmentAlarm[] | ClimateEquipmentAlarm[]
) => {
  let count = 0;
  let tempRow = [];
  const rows = [];

  if (!alarms || alarms.length === 0) {
    return [];
  }

  for (const [_, value] of Object.entries(alarms)) {
    tempRow.push(value);
    count++;
    if (count === 4) {
      rows.push(tempRow);
      count = 0;
      tempRow = [];
    }
  }

  if (count > 0) {
    rows.push(tempRow);
  }

  return rows;
};

export const getCardinalDirection = (angle) => {
  const directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
  return directions[Math.round(angle / 45) % 8];
};

export const disableEndTimeInput = (form: UntypedFormGroup) => {
  // Disable endTime inputs due to David's comment on this Trello card (https://trello.com/c/bq4vufN8/735-clima-revisi%C3%B3n-de-programaci%C3%B3n)
  const periodsControl = form.get('periods');
  if (periodsControl instanceof FormArray) {
    periodsControl.controls.forEach((period) => {
      period.get('endTime').disable();
    });
  }
};
