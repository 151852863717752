import { Component, Input, OnInit } from '@angular/core';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { environment } from '../../../../environments/environment';
import { ModulesLibService } from '../../../services/libraries/modules-lib.service';

@Component({
  selector: 'app-fields-dashboard-datalogger',
  templateUrl: './fields-dashboard-datalogger.component.html',
  styleUrls: ['./fields-dashboard-datalogger.component.scss'],
})
export class FieldsDashboardDataloggerComponent implements OnInit {
  @Input() equipment;
  img;
  module;
  constructor(
    public translationsLib: TranslationsLibService,
    private modulesLib: ModulesLibService
  ) {}

  ngOnInit() {
    this.img = environment.backend + '/' + this.equipment.img;
    console.log(this.equipment);
    this.modulesLib
      .getModuleByTerminalVidAndType(
        this.equipment.serialNumber,
        'irrigation_consulting'
      )
      .subscribe(
        (res) => {
          this.module = res;
        },
        (error) => {
          this.module = null;
        }
      );
  }
}
