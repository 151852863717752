import { Component, Input, OnInit } from '@angular/core';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { FieldDataModel } from 'src/app/fields/fields.model';
import { Router } from '@angular/router';
import { AppCommonsService } from '../../app-commons.service';

@Component({
  selector: 'app-fields-header',
  templateUrl: './fields-header.component.html',
  styleUrls: ['./fields-header.component.scss'],
})
export class FieldsHeaderComponent implements OnInit {
  @Input() field: FieldDataModel;
  @Input() buttons = false;
  @Input() space = true;
  route = '';
  loading = true;
  constructor(
    private commonService: AppCommonsService,
    public translations: TranslationsLibService,
    private router: Router
  ) {}

  ngOnInit() {
    this.route = '/field;vid=' + this.field.vid;
    this.commonService.getCrops().subscribe((res) => {
      this.loading = false;
    });
  }
  reloadField() {
    location.reload();
  }
  editField() {
    this.router.navigate(['./edit-field', { vid: this.field.vid }]);
  }
}
