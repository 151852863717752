import { Component, OnInit, ViewChild } from '@angular/core';
import { HelpersLibService } from '../../services/libraries/helpers-lib.service';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { ActivatedRoute } from '@angular/router';
import { TerminalsService } from '../terminals.service';
import { TerminalsLibService } from '../../services/libraries/terminals-lib.service';
import { TerminalDataModel } from '../terminals.model';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-terminals-admin-tabs',
  templateUrl: './terminals-admin-tabs.component.html',
  styleUrls: ['./terminals-admin-tabs.component.scss'],
})
export class TerminalsAdminTabsComponent implements OnInit {
  breadcrumbs: any = [];
  private tabSelected;
  private vid;
  field_name: string;
  field_vid: string;
  terminal: TerminalDataModel = null;
  ready = false;
  recycleMapTab = false;
  @ViewChild('tabGroup', { static: false }) tab: MatTabGroup;
  constructor(
    private helpersLib: HelpersLibService,
    public translationsLib: TranslationsLibService,
    private route: ActivatedRoute,
    private terminalsLib: TerminalsLibService,
    private terminalsService: TerminalsService
  ) {
    this.tabSelected = this.translationsLib.get('terminal_terminal');
  }

  ngOnInit() {
    this.helpersLib.sendEventPageView('Equipos-editar');
    this.vid = this.route.snapshot.paramMap.get('vid');
    this.getTerminal();
  }
  ngAfterViewInit() {
    this.helpersLib.focusTab(this.tab);
  }
  getTerminal() {
    this.terminalsService.getTerminal(this.vid).subscribe((res) => {
      this.terminal = res;
      this.terminalsService.getTerminal(this.vid).subscribe((response) => {
        this.field_name = response.field?.name;
        this.field_vid = response.field?.vid;
        this.breadcrumbs = [
          {
            text: this.field_name,
            disabled: false,
            to: ['/field', { vid: this.field_vid }],
            active: false,
          },
          {
            text: res.name,
            disabled: false,
            to: this.terminalsLib.generateRoute(res.vid, res.type),
            active: false,
          },
          {
            text: this.tabSelected,
            disabled: false,
            to: ' ',
            active: true,
          },
        ];
        this.ready = true;
      });
    });
  }
  changeToTab(evento) {
    this.recycleMapTab = true;
    this.tabSelected = evento.tab.textLabel;
    this.breadcrumbs = [
      {
        text: this.field_name,
        disabled: false,
        to: ['/field', { vid: this.field_vid }],
        active: false,
      },
      {
        text: this.terminal.name,
        disabled: false,
        to: this.terminalsLib.generateRoute(
          this.terminal.vid,
          this.terminal.type
        ),
        active: false,
      },
      {
        text: this.tabSelected,
        disabled: false,
        to: ' ',
        active: true,
      },
    ];
    this.helpersLib.setTitle(
      this.translationsLib.get('terminal_terminal'),
      this.tabSelected,
      this.translationsLib.get('edit')
    );
    try {
      // Destroy map on tab
      // eslint-disable-next-line @typescript-eslint/dot-notation
      window['map'].destroy();
    } catch (error) {
      console.log('[TerminalsAdminTab]: Destroyed map on tab change');
    }
    setTimeout(() => {
      this.recycleMapTab = false;
    }, 200);
  }
}
