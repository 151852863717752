import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainDashboardComponent } from './main-dashboard/main-dashboard.component';
import { AppCommonsModule } from '../commons/app-commons.module';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { TextAvatarModule } from '../text-avatar';
import { ColorPickerModule } from 'ngx-color-picker';
import { ReactiveFormsModule } from '@angular/forms';
import { WizardDashboardComponent } from './wizard-dashboard/wizard-dashboard.component';
import { SwiperModule } from 'swiper/angular';
import { WeatherModule } from '../weather/weather.module';
import { AuthModule } from '../auth/auth.module';
import { MainDashboardTerminalsNoMeteoPillsComponent } from './main-dashboard/main-dashboard-terminals-no-meteo-pills/main-dashboard-terminals-no-meteo-pills.component';
import { MainDashboardTerminalsSimpleMeteoPillsComponent } from './main-dashboard/main-dashboard-terminals-simple-meteo-pills/main-dashboard-terminals-simple-meteo-pills.component';
import { TerminalIrrigationConsultingPillComponent } from './widgets/terminal-irrigation-consulting-pill/terminal-irrigation-consulting-pill.component';
import {App360SharedModule} from "@nutricontrol/app360-shared";

@NgModule({
  declarations: [
    MainDashboardComponent,
    WizardDashboardComponent,
    MainDashboardTerminalsNoMeteoPillsComponent,
    MainDashboardTerminalsSimpleMeteoPillsComponent,
    TerminalIrrigationConsultingPillComponent,
  ],
    imports: [
        CommonModule,
        IonicModule,
        RouterModule,
        TextAvatarModule,
        ColorPickerModule,
        ReactiveFormsModule,
        AppCommonsModule,
        SwiperModule,
        WeatherModule,
        AuthModule,
        App360SharedModule,
    ],
  exports: [WizardDashboardComponent, TerminalIrrigationConsultingPillComponent],
})
export class DashboardModule {}
