import { Component, Input, OnInit } from '@angular/core';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { TerminalDataModel } from '../../../../terminals/terminals.model';
import { AppCommonsModule } from '../../../../commons/app-commons.module';
import { NgForOf } from '@angular/common';
@Component({
  standalone: true,
  selector: 'app-fields-dashboard-terminals-no-rights',
  templateUrl: './fields-dashboard-terminals-no-rights.html',
  styleUrls: ['./fields-dashboard-terminals-no-rights.scss'],
  imports: [AppCommonsModule, NgForOf],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class FieldsDashboardTerminalsNoRights implements OnInit {
  @Input() allTerminals: TerminalDataModel[];
  @Input() terminalVidsWithNoRights = [];
  noRightsTerminals: TerminalDataModel[] = [];
  constructor(public translationsLib: TranslationsLibService) {}
  ngOnInit() {
    this.allTerminals.forEach((terminal) => {
      if (this.terminalVidsWithNoRights.includes(terminal.vid)) {
        this.noRightsTerminals.push(terminal);
      }
    });
  }
}
