import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import {
  TerminalDataModel,
  TERMINAL_TYPES_IRRIGATION,
  TERMINAL_TYPES_CLIMATE,
  TERMINAL_TYPES_DATALOGGER,
  TERMINAL_TYPES_CONFIGURABLE,
} from '../../terminals/terminals.model';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { EquipmentGeneralTypes } from '../../farming/farming.model';
@Injectable({
  providedIn: 'root',
})
export class TerminalsLibService {
  constructor(
    public router: Router,
    private translationsLib: TranslationsLibService
  ) {}

  public getName(terminal: TerminalDataModel) {
    return terminal.name + ' - ' + terminal.vid;
  }
  public getType(type: number) {
    let str_type = '';
    if (TERMINAL_TYPES_IRRIGATION.includes(type)) {
      str_type = EquipmentGeneralTypes.irrigation;
    } else if (TERMINAL_TYPES_CLIMATE.includes(type)) {
      str_type = EquipmentGeneralTypes.climate;
    } else if (TERMINAL_TYPES_DATALOGGER.includes(type)) {
      str_type = EquipmentGeneralTypes.datalogger;
    } else if (TERMINAL_TYPES_CONFIGURABLE.includes(type)) {
      str_type = EquipmentGeneralTypes.configurable;
    } else {
      str_type = EquipmentGeneralTypes.unknown;
    }
    return String(str_type).toUpperCase();
  }

  public getMarker(type: number): string {
    let marker = '';
    if (TERMINAL_TYPES_IRRIGATION.includes(type)) {
      marker =
        environment.backend + '/static/terminals-vect/irrigation_marker.svg';
    } else if (TERMINAL_TYPES_CLIMATE.includes(type)) {
      marker =
        environment.backend + '/static/terminals-vect/climate_marker.svg';
    } else if (TERMINAL_TYPES_DATALOGGER.includes(type)) {
      marker =
        environment.backend + '/static/terminals-vect/datalogger_marker.svg';
    } else if (TERMINAL_TYPES_CONFIGURABLE.includes(type)) {
      marker =
        environment.backend + '/static/terminals-vect/configurable_marker.svg';
    }
    return marker;
  }
  public getNativeMarker(type: number): string {
    let marker = '';
    if (TERMINAL_TYPES_IRRIGATION.includes(type)) {
      marker =
        environment.backend + '/static/terminals-vect/irrigation_marker.png';
    } else if (TERMINAL_TYPES_CLIMATE.includes(type)) {
      marker =
        environment.backend + '/static/terminals-vect/climate_marker.png';
    } else if (TERMINAL_TYPES_DATALOGGER.includes(type)) {
      marker =
        environment.backend + '/static/terminals-vect/datalogger_marker.png';
    } else if (TERMINAL_TYPES_CONFIGURABLE.includes(type)) {
      marker =
        environment.backend + '/static/terminals-vect/configurable_marker.png';
    }
    return marker;
  }
  public getIrrigationNativeMarker(status): string {
    let marker = '';
    if (status === true) {
      marker =
        environment.backend + '/static/terminals-vect/irrigation_marker_on.png';
    } else {
      marker =
        environment.backend + '/static/terminals-vect/irrigation_marker.png';
    }
    return marker;
  }
  public getIrrigationValveMarker(status): string {
    let marker = '';
    if (status === true) {
      marker = environment.backend + '/static/terminals-vect/valve_on.svg';
    } else {
      marker = environment.backend + '/static/terminals-vect/valve.svg';
    }
    return marker;
  }
  public getIrrigationValveNativeMarker(status): string {
    let marker = '';
    if (status === true) {
      marker = environment.backend + '/static/terminals-vect/valve_on.png';
    } else {
      marker = environment.backend + '/static/terminals-vect/valve.png';
    }
    return marker;
  }
  public getFieldMarker(status): string {
    let marker = '';
    if (status === true) {
      marker = environment.backend + '/static/fields-vect/field_on.svg';
    } else {
      marker = environment.backend + '/static/fields-vect/field.svg';
    }
    return marker;
  }
  public getFieldNativeMarker(status): string {
    let marker = '';
    if (status === true) {
      marker = environment.backend + '/static/fields-vect/field_on.png';
    } else {
      marker = environment.backend + '/static/fields-vect/field.png';
    }
    return marker;
  }
  public generateRoute(vid: string, type: number) {
    if (TERMINAL_TYPES_IRRIGATION.includes(type))
      return '/farming/' + vid + '/irrigation';
    if (TERMINAL_TYPES_CLIMATE.includes(type))
      return '/farming/' + vid + '/climate';
    if (TERMINAL_TYPES_DATALOGGER.includes(type))
      return '/farming/' + vid + '/datalogger';
    if (TERMINAL_TYPES_CONFIGURABLE.includes(type))
      return '/farming/' + vid + '/configurable';
    if (type === 0) return '/edit-terminal';
  }
  public goToRoute(terminal: TerminalDataModel) {
    if (terminal.type !== 0) {
      this.router.navigate([this.generateRoute(terminal.vid, terminal.type)]);
    } else {
      this.router.navigate([
        this.generateRoute(terminal.vid, terminal.type),
        {
          vid: terminal.vid,
        },
      ]);
    }
  }
  public getClimateIcons(key) {
    switch (key) {
      case 'Met_time':
        return '<i class="bi bi-clock"></i>';
      case 'Met_temp':
        return '<i class="bi bi-thermometer-half"></i>';
      case 'Met_rain':
        return '<i class="bi bi-cloud-drizzle"></i>';
      case 'Met_windDir':
        return '<i class="bi bi-signpost-split"></i>';
      case 'Met_instRad':
        return '<i class="bi bi-brightness-high"></i>';
      case 'Met_freezeAl':
        return '<i class="bi bi-snow"></i>';
      case 'Met_pluv':
        return '<i class="bi bi-water"></i>';
      case 'Met_day':
        return '<i class="bi bi-brightness-alt-high"></i>';
      case 'Met_stormAl1':
        return '<i class="bi bi-cloud-lightning-rain"></i>';
      case 'Met_stormAl2':
        return '<i class="bi bi-cloud-lightning-rain"></i>';
      case 'Met_hum':
        return '<i class="bi bi-cloud-fill"></i>';
      case 'Met_windSpeed':
        return '<i class="bi bi-wind"></i>';
      case 'Met_cummRad':
        return '<i class="bi bi-calendar"></i>';
    }
  }

  public terminalNoRights() {
    Swal.fire({
      text: this.translationsLib.get('terminal_no_rights'),
      icon: 'error',
      confirmButtonColor: '#224261',
    }).then((value) => {
      window.location.href = '/home/dashboard';
    });
  }
}
