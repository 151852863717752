<app-loader *ngIf="loaded === false"></app-loader>
<div class="card" *ngIf="loaded === true">
  <div class="card-header">
    <h2 class="text-header-primary m-0">{{ translationsLib.get('notification_notifications') }}</h2>
  </div>
  <div class="card-body px-2 px-sm-4">
    <div class="mb-0 alert alert-info" *ngIf="!notificationPreferences.user_preferences">
      <ion-icon name="information-circle-outline" class="me-1" style="position: relative;top: 2px;"></ion-icon>
      <b>{{ translationsLib.get('notification_no_devices') }}</b>
    </div>

    <ng-container *ngIf="notificationPreferences.user_preferences">
      <h4 class="mt-0 help-title">{{ translationsLib.get('notifications_user_title') }}</h4>
      <p class="help-text">{{ translationsLib.get('notifications_user_text') }}</p>

      <div class="card" style="padding: 1rem 1.25rem;">
        <div class="row align-items-center">
          <div class="col-auto">
            <ion-icon name="notifications-outline" class="h1 mb-0"></ion-icon>
          </div>
          <div class="col ms-n2">

            <!-- Heading -->
            <h4 class="mt-0 mb-1">
              {{ translationsLib.get('notifications_toggle_user') }}
            </h4>

          </div>

          <div class="col-auto">

            <!-- Switch -->
            <div class="form-switch">
              <input class="form-check-input" type="checkbox"
                     [(ngModel)]="notificationPreferences.user_preferences.enabled" (change)="userNotificationChanged()">
            </div>

          </div>

        </div>
      </div>

      <div class="row" *ngIf="notificationPreferences.user_preferences.enabled">
        <div class="col-12 col-md-6 mb-4 mb-md-0">

          <h4 class="help-title">{{ translationsLib.get('notifications_terminal_title') }}</h4>
          <p class="help-text">{{ translationsLib.get('notifications_terminal_text') }}</p>

          <div class="list-group">
            <div class="list-group-item" *ngFor="let terminal of notificationPreferences.terminals_preferences">
              <div class="row align-items-center">
                <div class="col-auto">

                  <!-- Icon -->
                  <ion-icon name="layers-sharp" class="h1 mb-0"></ion-icon>

                </div>
                <div class="col ms-n2">

                  <!-- Heading -->
                  <h4 class="mt-0 mb-1">
                    {{ terminal.terminal_name }}
                  </h4>

                  <!-- Text -->
                  <small class="text-body-secondary">
                    {{ terminal.terminal_vid }}
                  </small>

                </div>
                <div class="col-auto">

                  <!-- Switch -->
                  <div class="form-switch">
                    <input class="form-check-input" type="checkbox"
                           [(ngModel)]="terminal.enabled" (change)="userTerminalNotificationChanged(terminal)">
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6">

          <h4 class="help-title">{{ translationsLib.get('notifications_device_title') }}</h4>
          <p class="help-text">{{ translationsLib.get('notifications_device_text') }}</p>

          <div class="list-group">
            <div class="list-group-item" *ngFor="let device of notificationPreferences.devices_preferences">
              <div class="row align-items-center">
                <div class="col-auto">

                  <ion-icon name="phone-portrait-outline" class="h1 mb-0"></ion-icon>

                </div>
                <div class="col ms-n2">

                  <!-- Heading -->
                  <h4 class="mt-0 mb-1">
                    {{ device.model }}

                    <!-- Icon Android-->
                    <ion-icon *ngIf="device.os === 'android'" name="logo-android" class="h5 mb-0"></ion-icon>

                    <!-- Icon iOS-->
                    <ion-icon *ngIf="device.os === 'ios'" name="logo-apple" class="h5 mb-0"></ion-icon>
                  </h4>

                  <!-- Text -->
                  <small class="text-body-secondary">
                    {{ device.os }} · {{ device.os_version }} · {{ device.device_id }}
                  </small>

                </div>
                <div class="col-auto">

                  <!-- Switch -->
                  <div class="form-switch">
                    <input class="form-check-input" type="checkbox"
                           [(ngModel)]="device.notification_enabled" (change)="userDeviceNotificationChanged(device)">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
