import { Component, Input, OnInit } from '@angular/core';
import { DataloggerService } from '../datalogger.service';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { MatTabsModule } from '@angular/material/tabs';
import { NgIf } from '@angular/common';
import { ChartsComponentComponent } from '../../../graphics/charts-component/charts-component.component';

@Component({
  standalone: true,
  selector: 'app-graphs-with-day-selector',
  templateUrl: './graphs-with-day-selector.component.html',
  styleUrls: ['./graphs-with-day-selector.component.scss'],
  imports: [MatTabsModule, ChartsComponentComponent, NgIf],
})
export class GraphsWithDaySelectorComponent implements OnInit {
  @Input() terminal_vid;
  @Input() sensor;
  @Input() elementsDisposition = 1;
  @Input() connections;
  @Input() equipment;
  sensorData: any = [];
  selected = 0;
  sensor24hrs: any = [];
  sensor2days: any = [];
  sensor7days: any = [];
  sensor30days: any = [];
  constructor(
    public translations: TranslationsLibService,
    public dataloggerService: DataloggerService
  ) {}
  ngOnInit() {
    this.getConnections();
  }

  getConnections() {
    this.connections.forEach((sensorConnected) => {
      if (typeof this.sensor === 'string') {
        if (sensorConnected.magnitude === this.sensor) {
          this.sensorData.push(sensorConnected);
        }
      } else {
        this.sensor.forEach((sensorDeseado) => {
          if (sensorConnected.magnitude === sensorDeseado) {
            this.sensorData.push(sensorConnected);
          }
        });
      }
    });
    this.createClones();
  }

  createClones() {
    this.sensor2days = JSON.parse(JSON.stringify(this.sensorData));
    this.sensor24hrs = JSON.parse(JSON.stringify(this.sensorData));
    this.sensor7days = JSON.parse(JSON.stringify(this.sensorData));
    this.sensor30days = JSON.parse(JSON.stringify(this.sensorData));
  }
}
