import { Component, Input, OnInit } from '@angular/core';
import { PermissionsService } from '../permissions.service';
import { ActivatedRoute } from '@angular/router';
import { SessionLibService } from '@nutricontrol/app360-shared';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { UserIdentityDataModel } from '../permission.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-field-permissions',
  templateUrl: './field-permissions.component.html',
  styleUrls: ['./field-permissions.component.scss'],
})
export class FieldPermissionsComponent implements OnInit {
  @Input() vid = '';
  filteredOptions: Observable<string[]>;
  permissions;
  canAdmin = false;
  installer: UserIdentityDataModel = null;
  owner: UserIdentityDataModel = null;
  regularUsers: UserIdentityDataModel[] = [];
  contacts;
  ready = false;

  constructor(
    private permissionService: PermissionsService,
    private route: ActivatedRoute,
    private session: SessionLibService,
    public translationsLib: TranslationsLibService
  ) {
    if (this.vid === '') {
      this.vid = this.route.snapshot.paramMap.get('vid');
    }
  }

  ngOnInit() {
    this.installer = this.installer || null;
    this.owner = this.owner || null;
    this.regularUsers = this.regularUsers || [];

    this.getPermissionsList();
  }

  getPermissionsList() {
    this.permissionService.getPermissionsList().subscribe((res) => {
      res.fields.forEach((field) => {
        if (field.vid === this.vid) {
          this.permissions = field.permission_mapping_identity;
          this.canAdmin = field.authed_user_can_admin;
          this.deleteNulls();
          this.getContacts();
        }
      });
    });
  }

  deleteNulls() {
    if (this.permissions.installer !== null) {
      this.installer = this.permissions.installer;
    }
    if (this.permissions.owner !== null) {
      this.owner = this.permissions.owner;
    }
    if (this.permissions.regular_users !== null) {
      if ('regular_users' in this.permissions) {
        this.regularUsers = this.permissions.regular_users;
      }
      this.regularUsers = Object.values(this.regularUsers);
    }
  }
  getContacts() {
    this.permissionService
      .getUserContacts(this.session.getSessionVid())
      .subscribe((res) => {
        this.contacts = res;
        this.ready = true;
      });
  }
}
