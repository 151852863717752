<app-loader *ngIf="loading === true"></app-loader>

<div class="my-2" *ngIf="loading === false">
  <div class="row" *ngIf="activationStatus === 1" style="margin: 0px">

    <div class="col-lg-2 d-none d-lg-block" *ngIf="deviceStatus===false">
      &nbsp;
    </div>
    <div class="col-12 col-lg-8 text-danger text-center py-3" *ngIf="deviceStatus===false">
      {{ translationsLib.get('terminal_is_not_online_tools_text') }}
    </div>
    <div class="col-lg-2 d-none d-lg-block" *ngIf="deviceStatus===false">
      &nbsp;
    </div>

    <app-progress-bar style="padding: 0px" [progress]="progress" (newItemEvent)="cancelPickUp()"></app-progress-bar>
  </div>
  <div class="row" *ngIf="activationStatus=== 0.5">
    <app-loader></app-loader>
  </div>
  <div class="card">
    <div class="row">
    <div class="col-12 col-md-6">
        <h2 class="title-red mt-2">
          {{translationsLib.get('terminal_data')}}
        </h2>
      <form [formGroup]="fillData">
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label class="form-label">
                {{ translationsLib.get('terminal_name') }}
              </label>
              <!-- Input -->
              <input type="text" class="form-control" formControlName="terminalName" [value]="fillData.value.terminalName">
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="form-label">
                {{ translationsLib.get('terminal_creation_date') }}
              </label>
              <p class="text-muted" style="margin-top: 8px">
                {{ formattedDate }}
              </p>
            </div>
            <div class="form-group d-none">
              <label class="form-label">
                {{ translationsLib.get('timezone') }}
              </label>
              <!-- Input -->
              <input [disabled]="true" type="text" class="form-control" formControlName="timeZone" [value]="fillData.value.timeZone">
            </div>
          </div>
        </div>
      </form>
      <div *ngIf="database !== 'datalogger'">
        <div class="row">
          <div class="col-8 col-lg-9">
            <h2 class="title-red" id="recoger">
              {{ translationsLib.get('terminal_retrieve_data') }}
            </h2>
            <p class="help text-muted" [innerHTML]="translationsLib.get('terminal_retrieve_data_text')"></p>
          </div>
          <div class="col-4 col-lg-3 text-end align-self-center">
            <button [disabled]="activationStatus=== 0.5 || activationStatus===1 || deviceStatus === false" class="btn btn-terciary" (click)="pickUpData()">
              {{ translationsLib.get('terminal_retrieve_data') }}
            </button>
          </div>
          <div *ngIf="deviceStatus === false" class="col-12">
            <p class="text-danger">
              {{ translationsLib.get('terminal_is_not_online_tools_text') }}
            </p>
          </div>
          <ng-container *ngIf="terminalData.type === 0">
            <div class="col-12">
              <span class="badge bg-warning">
                {{ translationsLib.get('warning') }}
              </span>
            </div>
            <div class="col-9 align-self-top">
              <div class="text-muted" [innerHTML]="translationsLib.get('terminal_configuration_type_0')"></div>
            </div>
            <div class="col-3 align-self-top text-end">
              <div class="row">
                <div class="col-12">
                  <a (click)="reload();" class="btn btn-primary" style="color: white">
                    <ion-icon name="refresh-outline"></ion-icon>
                  </a>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="col-12 align-self-center">
            <div class="badge bg-warning-subtle" [innerHTML]="translationsLib.get('terminal_configuration_type_0')">xxx</div>
          </div>
        </div>
        <!--
        Disabled as requested at https://trello.com/c/d9fV4YWV/481-fe-be-api-revisi%C3%B3n-finca-pre-navidad-2023
        <div class="row">
          <div class="col-9">
            <h2 class="title-red">
              {{ translationsLib.get('terminal_send_data') }}
            </h2>
            <p class="help" [innerHTML]="translationsLib.get('terminal_send_data_text')"></p>
          </div>
          <div class="col-3 text-end align-self-center">
            <button class="btn btn-primary" [disabled]="activationStatus=== 0.5 || activationStatus===1 || deviceStatus === false" (click)="setUpConfig()">
              {{ translationsLib.get('terminal_send_data') }}
            </button>
          </div>
        </div>
        -->
      </div>
      <div class="row" *ngIf="database === 'mcom' && this.terminalData.type < 50">
        <h2 class="title-red mt-2">
          {{translationsLib.get('terminal_templates')}}
        </h2>
        <p class="help text-muted" [innerHTML]="translationsLib.get('terminal_templates_data_text')"></p>
        <app-terminals-attach-template [terminalVid]="vid"></app-terminals-attach-template>
      </div>
      <div class="row" [hidden]="database !== 'datalogger'">
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-12">
              <h2 class="title-red">
                {{ translationsLib.get('DL_time_lectures') }}
              </h2>
            </div>
          </div>
          <div class="row">
            <div class="custom-slider">
              <ion-range aria-label="Custom range" [min]="0" [max]="7" [value]="transformedSleepingTime"  [snaps]="true" #sleeping></ion-range>
              <div id="tickmarks" class="tick-position">
                <p>5m</p>
                <p>10m</p>
                <p>15m</p>
                <p>30m</p>
                <p>1h</p>
                <p>6h</p>
                <p>12h</p>
                <p>24h</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 mb-4">
          <div class="row">
            <div class="col-9">
              <h2 class="title-red">
                {{ translationsLib.get('DL_time_reading') }}
              </h2>
            </div>
          </div>
          <div class="row">
            <div class="custom-slider">
              <ion-range aria-label="Custom range" [min]="0" [max]="8" [value]="transformedReadingTime" [ticks]="true" [snaps]="true" #reading></ion-range>
              <div id="tickmarks" class="tick-position">
                <p>0s</p>
                <p>1s</p>
                <p>5s</p>
                <p>10s</p>
                <p>20s</p>
                <p>30s</p>
                <p>40s</p>
                <p>50s</p>
                <p>60s</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="row mb-2">
        <div class="col-12" *ngIf="allValves!==null && allValves.length>0">
          <h2 class="mb-0 title-red mt-2">
            {{translationsLib.get('terminals_position_valves')}}
          </h2>
          <label class="m-0 text-muted" style="font-size: 13px">{{translationsLib.get('terminal_edit_valves_help')}}</label>
        </div>
        <div class="col-12" *ngIf="allValves!==null && allValves.length>0">
          <mat-form-field>
            <mat-select  [(ngModel)]="selectedValveToEdit" [placeholder]="translationsLib.get('terminal_edit_valves_select_placeholder')">
              <mat-optgroup [label]="translationsLib.get('terminal_terminal')">
                <mat-option [value]="terminalData.name">{{terminalData.name}}</mat-option>
              </mat-optgroup>
              <mat-optgroup [label]="translationsLib.get('irrigation_valves')">
                <ng-container *ngFor="let valve of allValves">
                  <mat-option [value]="valve.valve">{{valve.name || translationsLib.get('irrigation_valve') +' '+(stringToNumber(valve.valve)+1)}}</mat-option>
                </ng-container>
              </mat-optgroup>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
        <!--<app-webapp-maps *ngIf="terminalData!== null && this.fillData.value.terminalName !== '' && allValves!==null && !isNative" (changeAllValves)="allValves = $event" [allValves]="allValves" [markerCoordinates]="markerCoordinates" [coming]="'terminals-edit'" [terminalData]="terminalData"></app-webapp-maps>
        <app-native-maps *ngIf="terminalData!== null && isNative && allValves!==null && selectedValveToEdit!== undefined " [selectedValve]="selectedValveToEdit" [markerCoordinates]="markerCoordinates" [coming]="'terminals-edit'" [terminalData]="terminalData" [allValves]="allValves"></app-native-maps>-->
        <lib-leaflet-maps
          *ngIf="terminalData!== null && allValves!==null && selectedValveToEdit!== undefined "
          [selectedValve]="selectedValveToEdit"
          [selectedValveToEdit]="selectedValveToEdit"
          [markerCoordinates]="markerCoordinates"
          [coming]="'terminals-edit'"
          [terminalData]="terminalData"
          [allValves]="allValves"
          (terminalsEdit)="patchValues($event)"
        >
        </lib-leaflet-maps>
        <br>
        <div class="row float-end mx-1">
          <button class="btn btn-primary"(click)="saveTerminalChanges(sleeping, reading)">
            {{ translationsLib.get('save') }}
          </button>
        </div>
    </div>
  </div>
  </div>
</div>
