import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldsEditComponent } from './fields-edit/fields-edit.component';
import { IonicModule } from '@ionic/angular';
import { TextAvatarModule } from '../text-avatar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldsNewComponent } from './fields-new/fields-new.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { FieldsDashboardComponent } from './fields-dashboard/fields-dashboard.component';
import { TerminalsModule } from '../terminals/terminals.module';
import { AppCommonsModule } from '../commons/app-commons.module';
import { PermissionsModule } from '../permissions/permissions.module';
import { FieldsGeneralComponent } from './fields-general/fields-general.component';
import { FieldsTableComponent } from './fields-table/fields-table.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxPaginationModule } from 'ngx-pagination';
import { AuthModule } from '../auth/auth.module';
import { WeatherModule } from '../weather/weather.module';
import { HighchartsChartModule } from 'highcharts-angular';
import { EventsCalendarModule } from '../calendar/events-calendar.module';
import { RouterLink, RouterLinkWithHref } from '@angular/router';
import { FieldsDashboardTerminalsNoRights } from './fields-dashboard/fields-dashboard-widgets/fields-dashboard-terminals-no-rights/fields-dashboard-terminals-no-rights';
import { FarmingModule } from '../farming/farming.module';
import { MulticropComponent } from './widgets/multicrop/multicrop.component';
import { MulticropChildrenComponent } from './widgets/multicrop/multicrop-children/multicrop-children.component';
import { FieldsDashboardDataloggerComponent } from './fields-dashboard/fields-dashboard-datalogger/fields-dashboard-datalogger.component';
import { FieldsDashboardIrrigationComponent } from './fields-dashboard/fields-dashboard-irrigation/fields-dashboard-irrigation.component';
import { FieldsDashboardClimateComponent } from './fields-dashboard/fields-dashboard-climate/fields-dashboard-climate.component';
import { FieldsDashboardConfigurableComponent } from './fields-dashboard/fields-dashboard-configurable/fields-dashboard-configurable.component';
import { ConfigurableStatesComponent } from '../farming/configurable/configurable-states/configurable-states.component';
import { DataloggerDashboardTerminalsGeneralComponent } from '../farming/datalogger/datalogger-dashboard-terminals-general/datalogger-dashboard-terminals-general.component';
import { FieldsDashboardTerminalsErrorComponent } from './fields-dashboard/fields-dashboard-widgets/fields-dashboard-terminals-error/fields-dashboard-terminals-error.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SwiperModule } from 'swiper/angular';
import {App360SharedModule} from "@nutricontrol/app360-shared";

@NgModule({
  declarations: [
    FieldsEditComponent,
    FieldsNewComponent,
    FieldsDashboardComponent,
    FieldsGeneralComponent,
    FieldsTableComponent,
    FieldsDashboardDataloggerComponent,
    MulticropComponent,
    MulticropChildrenComponent,
    FieldsDashboardConfigurableComponent,
    FieldsDashboardIrrigationComponent,
    FieldsDashboardTerminalsErrorComponent,
  ],
    imports: [
        CommonModule,
        IonicModule,
        TextAvatarModule,
        ReactiveFormsModule,
        MatTabsModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        TerminalsModule,
        AppCommonsModule,
        PermissionsModule,
        Ng2SearchPipeModule,
        FormsModule,
        NgxPaginationModule,
        AuthModule,
        WeatherModule,
        HighchartsChartModule,
        HighchartsChartModule,
        HighchartsChartModule,
        HighchartsChartModule,
        EventsCalendarModule,
        RouterLinkWithHref,
        RouterLink,
        FieldsDashboardTerminalsNoRights,
        FarmingModule,
        FieldsDashboardClimateComponent,
        ConfigurableStatesComponent,
        DataloggerDashboardTerminalsGeneralComponent,
        NgSelectModule,
        SwiperModule,
        App360SharedModule,
    ],
  exports: [FieldsNewComponent, FieldsEditComponent],

  providers: [FieldsNewComponent, FieldsEditComponent],
})
export class FieldsModule {}
