import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { TerminalsLibService } from '../../../services/libraries/terminals-lib.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-device-header',
  templateUrl: './device-header.component.html',
  styleUrls: ['./device-header.component.scss'],
})
export class DeviceHeaderComponent implements OnInit {
  // @TODO: Tipar equipment a TerminalData o al propio del API
  @Input() equipment: any;
  @Input() link = '';
  @Input() button: any;
  @Input() showPrettyTypeName = true;
  @Input() showButton = true;
  img = '';
  pretty_type_name = '';
  vid = '';
  constructor(
    public router: Router,
    public translationsLib: TranslationsLibService,
    private terminalsLib: TerminalsLibService
  ) {}

  ngOnInit() {
    // Imagen
    this.img = '';
    if (typeof this.equipment?.image_path !== 'undefined') {
      this.img = environment.backend + '/' + this.equipment.image_path;
    } else if (typeof this.equipment?.img !== 'undefined') {
      this.img = environment.backend + '/' + this.equipment.img;
    }

    if (this.img.indexOf('static') < 0) {
      this.img += '/static/terminals-vect/unknown.svg';
    }

    // Pretty type name
    if (typeof this.equipment?.pretty_type_name !== 'undefined') {
      this.pretty_type_name = this.equipment.pretty_type_name;
    } else if (typeof this.equipment?.type !== 'undefined') {
      this.pretty_type_name = this.equipment.type;
    }
    if (this.pretty_type_name === '-' || this.pretty_type_name === '0') {
      this.pretty_type_name = this.translationsLib.get('terminal_unknown');
    }

    // serial number / vid
    if (typeof this.equipment?.vid !== 'undefined') {
      this.vid = this.equipment.vid;
    } else if (typeof this.equipment?.serialNumber !== 'undefined') {
      this.vid = this.equipment.serialNumber;
    }
  }
  clickButton(terminal: any) {
    if (this.link.trim() === '') {
      this.terminalsLib.goToRoute(terminal);
    } else {
      this.router.navigate([this.link]);
    }
  }
}
