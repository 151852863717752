<app-loader *ngIf="loader === true"></app-loader>
<div class="row mt-4" *ngIf="loader === false">
  <div class="col-12 col-md-4">
    <div class="card">
      <div class="card-body">
        <aside class="d-none d-lg-block">
          <img src="assets/img/reports/welcome_reports_tasks.svg" style="max-height: 365px">
          <hr class="hr hr-blurry mx-4">
        </aside>
        <section>
          <h2 class="text-center mt-0 mb-4 text-header-primary">{{translationsLib.get('reports_tasks_what_are')}}</h2>
          <p class="m-0">{{translationsLib.get('reports_tasks_explain')}}</p>
        </section>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-8">
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">
          <h2 style="color: #224261">{{translationsLib.get('reports_create_report_task')}}</h2>
        </div>
      </div>
      <div class="row mx-0 mt-3 print-no-padding">
        <div class="col-12 col-xl p-0 p-lg-2 print-no-padding">
          <div class="row mx-0 align-items-center">
            <section class="col-12 px-2 px-lg-4">
              <span class="text-muted" *ngIf="reportsTasks.length === 0">
                <i>
                  {{ translationsLib.get('reports_no_report_tasks') }}
                </i>
              </span>
              <!-- Existing one -->
              <article *ngFor="let reportTask of reportsTasks">
                <app-reports-tasks-items
                  [availableReports]="availableReports"
                  [irrigationTerminals]="irrigationTerminals"
                  [reportTask]="reportTask"
                  (reloadParentEvent)="reloadParent($event)"
                >
                </app-reports-tasks-items>
              </article>
            </section>
            <aside class="col-12 text-end">
              <button class="btn btn-primary mb-3" type="submit" (click)="createReportTask();" *ngIf="reportsTasksAvailableCount > 0">
                {{ translationsLib.get('reports_create_new_report_task') }}
              </button>
              <span *ngIf="reportsTasksAvailableCount === 0">
              {{ translationsLib.get('reports_tasks_available_count_exceeded') }}
            </span>
            </aside>
            <section class="col-12 px-2 px-lg-4" *ngIf="showCreateReportTask === true">
              <!-- Create new -->
              <article>
                <app-reports-tasks-items
                  [availableReports]="availableReports"
                  [irrigationTerminals]="irrigationTerminals"
                  [reportTask]="null"
                  (reloadParentEvent)="reloadParent($event)"
                >
                </app-reports-tasks-items>
              </article>
            </section>
            <aside id='newReportTask'></aside>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


