import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { SessionLibService } from '@nutricontrol/app360-shared';
import { ActivatedRoute, Router } from '@angular/router';
import { TerminalsService } from '../terminals.service';
import { FieldsService } from '../../fields/fields.service';
import { AppCommonsService } from '../../commons/app-commons.service';
import { FieldsDashboardComponent } from '../../fields/fields-dashboard/fields-dashboard.component';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HelpersLibService } from '../../services/libraries/helpers-lib.service';
import { environment } from '../../../environments/environment';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { QrLibService } from '../../services/libraries/qr-lib.service';
import { PlatformLibService } from '@nutricontrol/app360-shared';
import { PrePairingDataModel } from '../terminals.model';
import { SwiperComponent } from 'swiper/angular';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pair-terminals',
  templateUrl: './pair-terminals.component.html',
  styleUrls: ['./pair-terminals.component.scss'],
})
export class PairTerminalsComponent implements OnInit, AfterViewInit {
  @ViewChild('swiper') swiperElement!: SwiperComponent;
  prePairingData: PrePairingDataModel = null;
  private fb: FormBuilder = new FormBuilder();
  serialNumber = this.fb.group({
    serial: [''],
  });

  results = this.fb.group({
    pair_billing_me: [false],
    pair_owner_me: [false],
    pair_installer: [false],
    pair_want_billing: [false],
    pair_want_owner: [true],
    pair_want_installer: [true],
    pair_owner_email: [false],
    pair_installer_email: [false],
    proposedOwnerEmail: [''],
    proposedInstallerEmail: [''],
    nombre_equipo: [''],
    id_equipo: 0,
  });
  codeData: any;
  disabled = true;
  avatar: string;
  nonAttached;
  terminalFound: boolean;
  installerEnabled;
  vid;
  serialNumberRes;
  contacts;
  filteredOptions: Observable<string[]>;
  filteredOptions2: Observable<string[]>;
  image_path;
  id_equipo;
  numTerminal;
  breadcrumbs: any = [];
  native;
  constructor(
    private fieldService: FieldsService,
    private session: SessionLibService,
    private router: Router,
    private terminalsService: TerminalsService,
    private commonsService: AppCommonsService,
    private reloadMenu: FieldsDashboardComponent,
    private route: ActivatedRoute,
    private helpersLib: HelpersLibService,
    public translationsLib: TranslationsLibService,
    private qrLib: QrLibService,
    public platformLib: PlatformLibService
  ) {
    this.terminalFound = false;
  }

  ngOnInit() {
    this.breadcrumbs = [
      {
        text: this.translationsLib.get('pairing_title'),
        disabled: false,
        to: ' ',
        active: true,
      },
    ];

    this.native = this.platformLib.isNative();
    this.avatar = this.session.getAvatar();
    this.getNonAttachedList();
    this.filteredOptions = this.results
      .get('proposedInstallerEmail')
      .valueChanges.pipe(
        startWith(''),
        map((value) => this.filter(value.toString() || ''))
      );
    this.getUserContacts();
    this.filteredOptions2 = this.results
      .get('proposedOwnerEmail')
      .valueChanges.pipe(
        startWith(''),
        map((value) => this.filter(value.toString() || ''))
      );
    this.getUserContacts();
    this.helpersLib.sendEventTerminalPair();
  }
  ngAfterViewInit() {
    this.swiperElement.swiperRef.disable();
  }
  filter(value: string): string[] {
    if (value !== '') {
      const filterValue = value.toLowerCase();
      return this.contacts.filter((option) =>
        option.email.toLowerCase().includes(filterValue)
      );
    } else {
      return null;
    }
  }
  saveData() {
    const pairing = this.convertData();
    if (pairing.pair_installer !== 'me' && pairing.pair_owner !== 'me') {
      Swal.fire({
        text: this.translationsLib.get('pairing_no_owner_no_installer'),
        icon: 'error',
        confirmButtonColor: '#224261',
      });
    } else {
      this.terminalsService
        .postPairing(pairing, this.serialNumberRes)
        .subscribe((paired) => {
          Swal.fire({
            text: this.translationsLib.get('pairing_success'),
            icon: 'success',
            confirmButtonColor: '#224261',
          }).then((res) => {
            if (res.isConfirmed || res.isDismissed) {
              // Force to reload
              window.location.href =
                '/terminal-activation;vid=' + this.serialNumberRes;
              //if (paired.terminal_info.activated === true) {
              //this.router.navigate([
              //  './terminal-activation',
              //  { vid: this.serialNumberRes },
              //]);
              //}
            }
          });
        });
    }
  }

  convertData() {
    let billing = '';
    let owner = '';
    let installer = '';

    if (this.results.value.pair_billing_me) {
      billing = 'me';
    }
    if (this.results.value.pair_owner_me) {
      owner = 'me';
    } else if (this.results.value.pair_want_owner) {
      owner = this.results.value.proposedOwnerEmail;
    }
    if (this.results.value.pair_installer) {
      installer = 'me';
    } else if (this.results.value.pair_want_installer) {
      installer = this.results.value.proposedInstallerEmail;
    }

    return {
      pair_billing: billing,
      pair_owner: owner,
      pair_installer: installer,
    };
  }

  getNonAttachedList() {
    this.terminalsService.getNonAttached().subscribe((response) => {
      this.nonAttached = response;
      if (this.nonAttached.length === 0) {
        this.nonAttached = null;
      }
    });
  }

  searchPrePairing(vid: string) {
    this.serialNumberRes = vid;
    this.terminalsService.getPrePairing(vid).subscribe(
      (response) => {
        if (response) {
          if ('error' in response) {
            Swal.fire({
              icon: 'error',
              title: this.translationsLib.get('error'),
              text: this.translationsLib.get('pairing_error'),
              confirmButtonColor: '#224261',
            });
          } else {
            this.prePairingData = response;
            this.terminalFound = true;
            this.swiperElement.swiperRef.enable();
            this.swiperElement.swiperRef.slideNext();
            this.swiperElement.swiperRef.disable();
            this.installerEnabled = response.pair_installer_me_allowed;
            this.image_path =
              environment.backend + '/' + response.terminal_info_image_path;
            this.id_equipo = response.terminal_info_id;
            this.numTerminal = response.terminal_info_id;
            this.results.setValue({
              pair_billing_me: response.pair_billing_me_allowed,
              pair_owner_me: response.pair_owner_me_allowed,
              pair_installer: response.pair_installer_me_allowed,
              pair_owner_email: response.pair_owner_email_allowed,
              pair_installer_email: response.pair_installer_email_allowed,
              proposedOwnerEmail: '',
              proposedInstallerEmail: '',
              nombre_equipo: response.terminal_info_model_text,
              id_equipo: response.terminal_info_id,
              pair_want_installer: true,
              pair_want_owner: true,
              pair_want_billing: false,
            });
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: this.translationsLib.get('error'),
            text: this.translationsLib.get('pairing_error'),
            confirmButtonColor: '#224261',
          });
        }
      },
      (error) => {
        Swal.fire({
          icon: 'error',
          title: this.translationsLib.get('error'),
          text: this.translationsLib.get('pairing_error'),
          confirmButtonColor: '#224261',
        });
      }
    );
  }
  getUserContacts() {
    this.terminalsService
      .getUserContacts(this.session.getSessionVid())
      .subscribe((response) => {
        this.contacts = response;
      });
  }
  async scan() {
    this.codeData = null;
    this.qrLib.scaner().subscribe((res) => {
      this.codeData = res;
      this.searchPrePairing(this.codeData);
    });
  }
  goToPreviousSlide() {
    this.swiperElement.swiperRef.enable();
    this.swiperElement.swiperRef.slidePrev();
    this.swiperElement.swiperRef.disable();
  }
}
