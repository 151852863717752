//@ts-check
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppCommonsModule } from './commons/app-commons.module';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AuthModule } from './auth/auth.module';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtInterceptor } from '@nutricontrol/app360-shared';
import { TextAvatarModule } from './text-avatar';
import { ProfileModule } from './profile/profile.module';
import { FieldsModule } from './fields/fields.module';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { FieldsDashboardComponent } from './fields/fields-dashboard/fields-dashboard.component';
import { TerminalsModule } from './terminals/terminals.module';
import { PermissionsModule } from './permissions/permissions.module';
import { CanActivateViaAuthGuard } from './services/guards/guards';
import { SwiperModule } from 'swiper/angular';
import { NgxMaskModule } from 'ngx-mask';
import { WeatherModule } from './weather/weather.module';
import { ReportsModule } from './reports/reports.module';
import { InfoModule } from './info/info.module';
import { OrganizationsModule } from './organizations/organizations.module';
import { CodeInputModule } from 'angular-code-input';
import { SupportModule } from './support/support.module';
import { FarmingModule } from './farming/farming.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { IrrigationConsultingModule } from './modules/irrigation-consulting/irrigation-consulting.module';
import { ShepherdService } from 'angular-shepherd';
import { NotificationsModule } from './notifications/notifications.module';
import { GlobalErrorHandler } from './interceptors/globalerror.interceptors';
import { App360BillingSharedModule } from '@nutricontrol/app360-billing-shared';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AppCommonsModule,
    IonicModule,
    AuthModule,
    HttpClientModule,
    CodeInputModule,
    TextAvatarModule,
    ProfileModule,
    FieldsModule,
    NoopAnimationsModule,
    TerminalsModule,
    PermissionsModule,
    SwiperModule,
    ReportsModule,
    InfoModule,
    WeatherModule,
    NgxMaskModule.forRoot(),
    OrganizationsModule,
    FarmingModule,
    SupportModule,
    DashboardModule,
    IrrigationConsultingModule,
    NotificationsModule,
    App360BillingSharedModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    FieldsModule,
    FormBuilder,
    GoogleAnalytics,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    FieldsDashboardComponent,
    CanActivateViaAuthGuard,
    ShepherdService,
    { provide: 'ENVIRONMENT', useValue: environment },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
