<app-loader *ngIf="loader === true"></app-loader>

<section class="reports-main-subcomponent-container" *ngFor="let reportData of reportsData;">
  <div class="card pb-3" *ngIf="reportData.ready === true">
    <div class="card-header">
      <!-- Title -->
      <h2 class="card-header-title mb-3">
        {{ reportData.terminalNameVid }}
      </h2>
      <h2 class="card-header-subtitle">
        {{ reportData.terminalFieldName }}
      </h2>
    </div>
    <div class="row mx-2 mt-3 print-no-padding">
      <div class="col-12 p-0 p-lg-2 alarms-container print-no-padding">
        <div class="alert alert-info mb-2" role="alert" *ngIf="reportData.alarms.length <= 0">
          {{ translationsLib.get('reports_no_data') }}
        </div>

        <article *ngIf="reportData.alarms.length > 0">
          <app-reports-alarms-table
            [alarmColumnsToDisplay]="alarmColumnsToDisplay"
            [reportName]="reportData.reportName"
            [data]="reportData.alarms"
            [count]="reportData.alarms.length"
            [tablePageItemNumber]="createdReport.tablePageItemNumber"
            [requestData]="{terminals_vid: [reportData.terminalVid], end: reportData.end, begin: reportData.begin, type: 'alarms'}"
          ></app-reports-alarms-table>
        </article>
      </div>
    </div>
  </div>
</section>
