import { Component, Input, OnInit } from '@angular/core';
import { TranslationsLibService } from '@nutricontrol/app360-shared';
import { IrrigationBandDataModel } from '../../../modules/modules.model';

@Component({
  selector: 'app-terminal-irrigation-consulting-pill',
  templateUrl: './terminal-irrigation-consulting-pill.component.html',
  styleUrls: ['./terminal-irrigation-consulting-pill.component.scss'],
})
export class TerminalIrrigationConsultingPillComponent implements OnInit {
  @Input() irrigationBand: IrrigationBandDataModel = null;
  @Input() tensiometer: any = {};
  @Input() class = 'normal';
  @Input() status = 1;

  constructor(public translationsLib: TranslationsLibService) {}

  ngOnInit() {}
}
